import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getCustomers, getCategoryList, getCustodyAutoComplete, getProductList, getReports } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import Autocomplete from 'react-autocomplete';
import { DatePicker } from 'antd';
import Select from 'react-select';
import dayjs from 'dayjs';
import html2pdf from 'html2pdf.js';
import PDFTemplate from './template/pdf_template';
import * as XLSX from "xlsx";

const Report = (props) => {
    const history = useHistory();

    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const merchant_name = props.auth.auth.merchant_name;
    const merchant_code = (props.auth.auth.merchant_code) ? props.auth.auth.merchant_code : "";
    const user_type = (props.auth.auth.user_type) ? props.auth.auth.user_type : "";

    const today = new Date();
    // Format today's date to YYYY-MM-DD
    const startDefaultDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-01`;
    const endDefaultDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [filter, setFilter] = useState({
        filter_category: [],
        filter_product: "",
        filter_date_start: startDefaultDate,
        filter_date_end: endDefaultDate,
        filter_report: "",
        filter_sort_by: ""
    });

    const [dateData, setDateData] = useState({
        filter_date_start: dayjs(startDefaultDate),
        filter_date_end: dayjs(endDefaultDate),
    })

    const [error, setError] = useState({
        filter_report: ""
    })

    // const [data, setData] = useState([])

    const reportSelections = [
        { name: "Custody Detail Report (Active)", code: "custody_detail_report_active" },
        { name: "Custody Detail Report (Expired)", code: "custody_detail_report_expired" },
        { name: "Custody Detail Report (Checked Out)", code: "custody_detail_report_checkout" },
        { name: "Custody Detail Report (Deleted)", code: "custody_detail_report_deleted" },
        { name: "Custody Summary Report (Active)", code: "custody_summary_report_active" },
        { name: "Custody Summary Report (Expired)", code: "custody_summary_report_expired" },
        { name: "Custody Summary Report (Checked Out)", code: "custody_summary_report_checkout" },
        { name: "Custody Summary Report (Deleted)", code: "custody_summary_report_deleted" },
        { name: "Staff Transaction Report", code: "staff_transaction_report" },
        { name: "Custody In Detail Report", code: "custody_in_detail_report" },
        { name: "Custody In Summary Report", code: "custody_in_summary_report" },
        { name: "Transfer Detail Report", code: "transfer_detail_report" },
        { name: "Transfer Summary Report", code: "transfer_summary_report" }
    ]

    const sortBySelection = [
        { name: "Custody Date Newest First", code: "custody_date-asc" },
        { name: "Custody Date Oldest First", code: "custody_date-desc" },
        { name: "Entry Date Newest First", code: "created_date-asc" },
        { name: "Entry Date Oldest First", code: "created_date-desc" },
    ]

    const [categoryData, setCategoryData] = useState([
        {
            value: "",
            label: "",
        }
    ]);

    const [productData, setProductData] = useState([
        {
            value: "",
            label: "",
        }
    ]);

    const [reportName, setReportName] = useState();

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });

        if (e.target.name == 'filter_report') {
            const filteredDataArr = reportSelections.filter((item) => item.code === e.target.value);
            setReportName(filteredDataArr[0].name);
        }
    };

    const datePickerOnchange = (e, name) => {
        const tmp2 = dateData;
        tmp2[name] = e;
        setDateData({ ...tmp2 })

        const tmp = filter;
        tmp[name] = (e) ? e.format(translate(lang, "date_format")) : "";
        setFilter({ ...tmp });
    };

    useEffect(() => {
        getCategories();
        getProducts();
        // getList(filter);

        if (merchant_code && merchant_code == 'TIPSYTBM' && user_type && user_type == 'bartender') {
            history.push('/dashboard');
        }
    }, [])

    async function getCategories() {
        let query = {
            merchant_id: merchant_id,
        };

        let res = await getCategoryList(query);

        if (res?.status === 'success') {
            let tmp_arr = [];

            res.data.forEach(element => {
                let tmp_data = { value: element.name, label: element.name }
                tmp_arr.push({ ...tmp_data });
            });

            setCategoryData(tmp_arr);
        } else {
        }
    }

    async function getProducts() {
        let query = {
            merchant_id: merchant_id,
            limit: 9999
        };

        let res = await getProductList(query);

        if (res?.status === 'success') {
            let tmp_arr = [];

            res.data.results.forEach(element => {
                let tmp_data = { value: element.id, label: element.description }
                tmp_arr.push({ ...tmp_data });
            });

            setProductData(tmp_arr);

        } else {
        }
    }

    // AUTOCOMPLETE START
    // const timerRef = useRef(null);
    // const [custodyAutoCompleteData, setCustodyAutoCompleteData] = useState([]);
    // const [showRemoveAutoCompleteValue1, setShowRemoveAutoCompleteValue1] = useState(false);
    // const [autocompleteValue, setAutoCompleteValue] = useState({
    //     filter_name: "",
    //     filter_product_input: ""
    // });

    // async function custodyAutocomplete() {
    //     let query = { input: autocompleteValue.filter_product_input, merchant_id: props.merchant_id, limit: 5 }

    //     let res = await getCustodyAutoComplete(query);

    //     if (res?.status === 'success') {
    //         setCustodyAutoCompleteData(res.data)
    //     } else {
    //     }
    // }

    // const autocompleteOnChange = (e, type) => {
    //     const tmp = autocompleteValue;
    //     tmp[e.target.name] = e.target.value;
    //     setAutoCompleteValue({ ...tmp });

    //     if (!e.target.value) {
    //         setCustodyAutoCompleteData([]);
    //     }

    //     if (type == 'custody') {
    //         if (autocompleteValue.filter_product_input) {
    //             clearTimeout(timerRef.current);
    //             timerRef.current = setTimeout(() => {
    //                 custodyAutocomplete();
    //             }, 500)
    //         }
    //     }
    // }

    // const autocompleteOnSelect1 = (e, value) => {
    //     const tmp = filter;
    //     tmp['filter_product_id'] = value.id;
    //     setFilter({ ...tmp });

    //     setAutoCompleteValue({ filter_product_input: value.description });
    //     setShowRemoveAutoCompleteValue1(true);
    //     setCustodyAutoCompleteData([]);
    // }

    // const menuStyle = {
    //     borderRadius: '3px',
    //     boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
    //     background: 'rgba(255, 255, 255, 0.9)',
    //     padding: '2px 0',
    //     position: 'absolute',
    //     left: '0px',
    //     top: '40px',
    //     zIndex: '9',
    // };

    // const autoCompleteClose1 = (e) => {
    //     e.preventDefault();

    //     const tmp = autocompleteValue;
    //     tmp['filter_product_input'] = e.target.value;
    //     setAutoCompleteValue({ ...tmp });

    //     const tmp2 = filter;
    //     tmp2['filter_product_id'] = "";
    //     setFilter({ ...tmp2 });

    //     setCustodyAutoCompleteData([]);
    //     setShowRemoveAutoCompleteValue1(false);
    // }

    // AUTOCOMPLETE END

    const onChangeSelect = (e, name) => {
        let tmp = filter;
        tmp[name] = [];

        if (e !== null && e.length > 0) {
            e.forEach(element => {
                const tmp_value = `'${element.value}'`;
                tmp[name].push(tmp_value);
            })
        }

        setFilter({ ...tmp });
    }

    const submitFilter = async (e, type) => {
        e.preventDefault();
        let check = true;
        let error = {
            filter_report: ""
        }

        if (!filter.filter_report) {
            check = false;
            error.filter_report = translate(lang, "errorRequired");
        }

        setError({ ...error });

        if (check) {
            console.log('filter', filter)
            console.log('props', props)
            let res = await getReports(filter, props);
            console.log('res', res)
            if (type == 'pdf') {
                try {
                    // const blob = await res.blob();
                    const file = new Blob(
                        [res],
                        { type: 'application/pdf' }
                    );
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                } catch (error) {
                    console.log('error', error);
                }
                return;
            }
            if (res?.status === 'success') {
                const fileName = `${filter.filter_report}-${filter.filter_date_start}-${filter.filter_date_end}.pdf`;
                if (type == 'pdf') {
                    const element = <PDFTemplate data={res.data} filter={filter} report_name={reportName} merchant_name={merchant_name} />;
                    const htmlContent = ReactDOMServer.renderToString(element);

                    const opt = {
                        margin: 10,
                        filename: fileName,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
                    };

                    // download file
                    html2pdf().from(htmlContent).set(opt).save();

                    // open the file to new tab
                    html2pdf().from(htmlContent).set(opt).toPdf().get('pdf').then(function (pdf) {
                        window.open(pdf.output('bloburl'), '_blank');
                    });

                    // // console.log(res.data);
                    // const url = res.data.url;

                    // // Open a new tab with the specified URL
                    // window.open(url, '_blank');

                } else {
                    const result = { data: res.data.results, fileName };


                    exportExcel(result);
                }
            } else {
                promptMessage(lang, 'error', res.message)
            }
        }
    }

    function processData(data, restructure_type) {
        let tmp_data2 = [];

        data.forEach((val, ind) => {
            let tmp_data = [];
            if (restructure_type == 'detail') {
                tmp_data.push(ind + 1);
                tmp_data.push(val.prod_desc);
                tmp_data.push(val.customer_name);
                tmp_data.push(val.customer_phone);
                tmp_data.push(val.code);
                tmp_data.push(val.custody_date);
                tmp_data.push((val.expiry_type == 'never expired') ? val.expiry_type : val.expiry_date);
                tmp_data.push(val.net_balance);
            } else if (restructure_type == 'summary' || restructure_type == 'custody_in_summary_report' || restructure_type == 'transfer_summary_report') {
                tmp_data.push(ind + 1);
                tmp_data.push(val.product);
                tmp_data.push(val.total);
            } else if (restructure_type == 'custody_in_detail_report') {
                tmp_data.push(ind + 1);
                tmp_data.push(val.prod_desc);
                tmp_data.push(val.customer_name);
                tmp_data.push(val.customer_reference);
                tmp_data.push(val.customer_phone);
                tmp_data.push(val.remark);
                tmp_data.push(val.code);
                tmp_data.push(val.custody_date);
                tmp_data.push(val.net_balance);
            } else if (restructure_type == 'transfer_detail_report') {
                tmp_data.push(ind + 1);
                tmp_data.push(val.created_date);
                tmp_data.push(val.prod_desc);
                tmp_data.push(val.code);
                tmp_data.push(val.customer_reference);
                tmp_data.push(val.customer_name);
                tmp_data.push(val.customer_phone);
                tmp_data.push(val.remark);
                tmp_data.push(val.net_balance);
            } else if (restructure_type == 'staff_transaction_report') {
                // tmp_data.push(ind + 1);
                tmp_data.push(val.updated_date);
                tmp_data.push(val.updated_by_info.display_name);
                tmp_data.push(val.code);
                tmp_data.push((val.rec_type == 0 || val.status == 'Deleted') ? "Deleted" : `${val.type} ${val.description}`);
                let tmp_balance_str = (val.net_balance > 0) ? `+${val.net_balance}` : val.net_balance;
                let detail = `${tmp_balance_str} ${val.prod_uom_desc} ${val.prod_desc}`;
                let detail2 = `${val.customer_name} - ${val.customer_phone}\r\nCustody On ${val.custody_date}\r\n${val.expiry_type}`;
                tmp_data.push(detail);
                tmp_data.push(detail2);
                tmp_data.push(val.bartender);
            }

            tmp_data2.push(tmp_data);
        });

        return tmp_data2;
    }

    const exportExcel = (res) => {
        const wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet([]);

        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const alphabetArr = alphabet.split("");

        // for row_count
        let count_1 = 1;

        // for alphabet
        let count_2 = 0;

        const rightAlignStyle = { alignment: { horizontal: 'right' } };

        let tmp_arr = [];

        let restructure_type = '';
        let column_name_arr = [];

        switch (filter.filter_report) {
            case "custody_detail_report_active":
                restructure_type = 'detail';
                column_name_arr = ["No.", "Custody Item", "Customer", "Phone", "Custody No", "Transaction Date", "Expiry Date", "Quantity"];
                break;
            case "custody_detail_report_expired":
                restructure_type = 'detail';
                column_name_arr = ["No.", "Custody Item", "Customer", "Phone", "Custody No", "Transaction Date", "Expiry Date", "Quantity"];
                break;
            case "custody_detail_report_checkout":
                restructure_type = 'detail';
                column_name_arr = ["No.", "Custody Item", "Customer", "Phone", "Custody No", "Transaction Date", "Expiry Date", "Quantity"];
                break;
            case "custody_detail_report_deleted":
                restructure_type = 'detail';
                column_name_arr = ["No.", "Custody Item", "Customer", "Phone", "Custody No", "Transaction Date", "Expiry Date", "Quantity"];
                break;
            case "custody_summary_report_active":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Balance"];
                break;
            case "custody_summary_report_expired":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Balance"];
                break;
            case "custody_summary_report_checkout":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Balance"];
                break;
            case "custody_summary_report_deleted":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Balance"];
                break;
            case "staff_transaction_report":
                restructure_type = filter.filter_report;
                column_name_arr = ["Date Time", "Updated By", "Code", "Type", "Custody", "Details", "Bartender"];
                break;
            case "custody_in_detail_report":
                restructure_type = filter.filter_report;
                column_name_arr = ["No.", "Custody Item", "Customer", "Cust. Ref", "Phone", "Remark", "Custody No", "Custody Date", "Balance"];
                break;
            case "custody_in_summary_report":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Balance"];
                break;
            case "transfer_detail_report":
                restructure_type = filter.filter_report;
                column_name_arr = ["No.", "Date Time", "Item", "Custody No", "Cust. Ref", "Customer", "Phone", "Remark", "Quantity"];
                break;
            case "transfer_summary_report":
                restructure_type = 'summary';
                column_name_arr = ["No.", "Custody Item", "Quantity"];
                break;

            default:
                break;
        }
        if (restructure_type != 'staff_transaction_report') {
            res.data.forEach((value, index) => {
                XLSX.utils.sheet_add_aoa(ws, [[`Category: ${value.category}`]], { origin: `${alphabetArr[count_2]}${count_1}` });
                count_1 = count_1 + 1;

                // set header
                column_name_arr.forEach((value) => {
                    XLSX.utils.sheet_add_aoa(ws, [[value]], { origin: `${alphabetArr[count_2]}${count_1}` });
                    count_2 = count_2 + 1;
                });

                count_2 = 0;

                count_1 = count_1 + 1;

                // EXCEL BODY
                const processedData = processData(value.items, restructure_type);

                processedData.forEach((values) => {
                    values.forEach((val) => {
                        XLSX.utils.sheet_add_aoa(ws, [[val]], { origin: `${alphabetArr[count_2]}${count_1}` });
                        count_2 = count_2 + 1;
                    });

                    count_2 = 0;
                    count_1 = count_1 + 1;
                });

                // for merge cell
                tmp_arr.push({ s: { r: (count_1 - 1), c: 0 }, e: { r: (count_1 - 1), c: (column_name_arr.length - 2) } });

                XLSX.utils.sheet_add_aoa(ws, [["Total"]], { origin: `${alphabetArr[count_2]}${count_1}` });
                count_2 = count_2 + (column_name_arr.length - 1);
                if (restructure_type == 'detail' || restructure_type == 'transfer_detail_report' || restructure_type == 'custody_in_detail_report') {
                    XLSX.utils.sheet_add_aoa(ws, [[value.total_quantity]], { origin: `${alphabetArr[count_2]}${count_1}` });
                } else if (restructure_type == 'summary') {
                    XLSX.utils.sheet_add_aoa(ws, [[value.total]], { origin: `${alphabetArr[count_2]}${count_1}` });
                }
                count_2 = 0;

                const cellAddress = XLSX.utils.encode_cell({ r: (count_1 - 1), c: 0 });
                ws[cellAddress].s = rightAlignStyle;

                count_1 = count_1 + 1;
                count_1 = count_1 + 1;
            })
        } else {
            column_name_arr.forEach((value) => {
                XLSX.utils.sheet_add_aoa(ws, [[value]], { origin: `${alphabetArr[count_2]}${count_1}` });
                count_2 = count_2 + 1;
            });

            count_2 = 0;

            count_1 = count_1 + 1;

            // EXCEL BODY
            const processedData = processData(res.data, restructure_type);

            processedData.forEach((values) => {
                values.forEach((val) => {
                    XLSX.utils.sheet_add_aoa(ws, [[val]], { origin: `${alphabetArr[count_2]}${count_1}` });
                    count_2 = count_2 + 1;
                });

                count_2 = 0;
                count_1 = count_1 + 1;
            });

            // const cellAddress = XLSX.utils.encode_cell({ r: 0, c: 3 });
            // ws[cellAddress].s = { wrapText: true };
            // ws[cellAddress].s.wrapText = true; // Enable text wrapping for the cell
            // ws[cellAddress].s.alignment = { wrapText: true }; // Set alignment to wrap text
        }

        if (tmp_arr) {
            ws['!merges'] = tmp_arr;
        }

        // ws['!cols'] = [
        //     { wch: 10 },
        //     { wch: 15 },
        //     { wch: 20 },
        //     { wch: 15 },
        //     { wch: 15 },
        //     { wch: 25 },
        //     { wch: 15 },
        //     { wch: 10 },
        // ];

        XLSX.utils.book_append_sheet(wb, ws, `Report`);
        XLSX.writeFile(wb, `${res.fileName}.xlsx`);
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, "report")}
                    </h3>
                </div>
                <div className="card-body">
                    <div className="clearfix"></div>
                    <div className="header-filter my-4">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="filter-category">{translate(lang, 'category')}</label>
                                    <Select
                                        className="custom-form-control"
                                        closeMenuOnSelect={false}
                                        // defaultValue={[categoryData[4], categoryData[5]]}
                                        isMulti
                                        options={categoryData}
                                        onChange={(e) => onChangeSelect(e, "filter_category")}
                                    />
                                    {/* <select className="form-control" name="filter_category" onChange={(e) => inputOnchange(e)}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(categoryData && categoryData.length > 0)
                                            ?
                                            categoryData.map((value) => (
                                                <option key={value.id} value={value.name}>{value.name}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3 position-relative">
                                    <label htmlFor="filter-product">{translate(lang, 'productName')}</label>
                                    {/* <Autocomplete
                                        items={custodyAutoCompleteData}
                                        getItemValue={(item) =>
                                            item.description
                                        }
                                        renderItem={(item, isHighlighted) =>
                                            <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                {item.description} {item.code ? '- ' + item.code : <></>}
                                            </div>
                                        }
                                        value={autocompleteValue.filter_product_input} // Initially, no value is set
                                        onChange={(e) => autocompleteOnChange(e, "custody")}
                                        onSelect={(value, item) => autocompleteOnSelect1(value, item)}
                                        renderMenu={(items, value, style) => {
                                            return (
                                                <div style={{ ...style, ...menuStyle }}>
                                                    {items}
                                                </div>
                                            );
                                        }}
                                        inputProps={{
                                            className: 'form-control',
                                            placeholder: translate(lang, 'productName'),
                                            name: 'filter_product_input',
                                        }}
                                        wrapperStyle={{ position: 'relative' }}
                                    />
                                    {
                                        (showRemoveAutoCompleteValue1)
                                            ?
                                            <a className="auto-complete-close" onClick={e => { autoCompleteClose1(e) }} style={{ "bottom": "7px" }}><i className="fas fa-times"></i></a>
                                            : <></>
                                    } */}
                                    <Select
                                        className="custom-form-control"
                                        closeMenuOnSelect={false}
                                        // defaultValue={[productData[4], productData[5]]}
                                        isMulti
                                        options={productData}
                                        onChange={(e) => onChangeSelect(e, "filter_product")}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="filter-date-start">{translate(lang, 'dateStart')}</label>
                                    <DatePicker id="filter-date-start" className="d-block date-picker-custom-form-control" value={dateData.filter_date_start} format={translate(lang, "date_format")} name="filter_date_start" onChange={(e) => datePickerOnchange(e, "filter_date_start")} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="filter-date-end">{translate(lang, 'dateEnd')}</label>
                                    <DatePicker id="filter-date-end" className="d-block date-picker-custom-form-control" value={dateData.filter_date_end} format={translate(lang, "date_format")} name="filter_date_end" onChange={(e) => datePickerOnchange(e, "filter_date_end")} />
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-3 required">
                                    <label htmlFor="filter-report" className="form-label">{translate(lang, 'report')}</label>
                                    <select id="filter-report" className="form-control" name="filter_report" onChange={(e) => inputOnchange(e)}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(reportSelections && reportSelections.length > 0)
                                            ?
                                            reportSelections.map((value) => (
                                                <option key={value.code} value={value.code}>{value.name}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select>
                                    {(error.filter_report)
                                        ?
                                        <label className="text-danger">{error.filter_report}</label>
                                        : <></>
                                    }
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group mb-3">
                                    <label htmlFor="filter-sort-by">{translate(lang, 'sortBy')}</label>
                                    <select id="filter-sort-by" className="form-control" name="filter_sort_by" onChange={(e) => inputOnchange(e)}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(sortBySelection && sortBySelection.length > 0)
                                            ?
                                            sortBySelection.map((value) => (
                                                <option key={value.code} value={value.code}>{value.name}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="header-button">
                            <div className="float-start">
                                <button className="btn btn-primary mt-3" onClick={(e) => submitFilter(e, 'pdf')}>{translate(lang, 'exportPDF')}</button>
                                <button className="btn btn-primary ms-3 mt-3" onClick={(e) => submitFilter(e, 'excel')}>{translate(lang, 'exportExcel')}</button>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(Report);