import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getUser, getUserRole, addUser, editUser } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";

const UserForm = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [userRoleData, setUserRoleData] = useState([]);
    const [data, setData] = useState({
        loginID: "",
        name: "",
        userType: "admin",
        phone: "",
        email: "",
    });
    const [error, setError] = useState({
        loginID: "",
        name: "",
        password: "",
        confirmPassword: "",
        phone: ""
    });

    useEffect(() => {
        getUserRoleList();

        if (type === 'create') {
            setData({
                loginID: "",
                name: "",
                userType: "admin",
                phone: "",
                email: "",
            })
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/settings/staff');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        // let params = { id: slug };
        let res = await getUser(slug);

        setData({
            loginID: "",
            name: "",
            userType: "admin",
            phone: "",
            email: "",
        });

        if (res.status === 'success') {
            let data = res.data;

            setData({
                loginID: (data.login_id) ? data.login_id : "",
                name: (data.display_name) ? data.display_name : "",
                phone: (data.phone) ? data.phone : "",
                email: (data.email) ? data.email : "",
                userType: (data.user_type) ? data.user_type : "admin",
            });

        } else {
            history.push('/settings/staff');
        }
    }

    const submitForm = async () => {
        let check = true;
        let error = {
            loginID: "",
            name: "",
            password: "",
            confirmPassword: "",
            phone: "",
        }

        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }

        if (!data.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }

        if (data.password || data.confirmPassword) {
            if (data.password != data.confirmPassword) {
                check = false;
                error.confirmPassword = translate(lang, "errorPasswordNotMatch");
            }
        }

        if (type == 'create') {
            if (!data.password) {
                check = false;
                error.password = translate(lang, "errorRequired");
            }

            if (!data.confirmPassword) {
                check = false;
                error.confirmPassword = translate(lang, "errorRequired");
            }

            if (!data.loginID) {
                check = false;
                error.loginID = translate(lang, "errorRequired");
            }
        }

        setError({ ...error });
        if (check) {
            const formData = {
                "login_id": data.loginID,
                "name": data.name,
                "phone": data.phone,
                "email": data.email,
                "user_type": data.userType,
                "password": data.password,
                "merchant_id": merchant_id
            }

            if (type == 'create') {
                await addUser(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/staff');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                await editUser(formData, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/staff');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    async function getUserRoleList() {
        let res = await getUserRole();

        if (res?.status === 'success') {
            setUserRoleData(res.data);
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "edit" ? "editUser" : "createUser"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">

                            <div className="form-group mb-3 required">
                                <label htmlFor="input-login-id" className="form-label">{translate(lang, 'loginID')}</label>
                                <input id="input-login-id" type="text" className="form-control" name="loginID" value={data.loginID} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} readOnly={(type == 'edit')} />
                                {(error.loginID)
                                    ?
                                    <label className="text-danger">{error.loginID}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3 required">
                                <label htmlFor="input-name" className="form-label">{translate(lang, 'name')}</label>
                                <input id="input-name" type="text" className="form-control" name="name" value={data.name} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                {(error.name)
                                    ?
                                    <label className="text-danger">{error.name}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="input-user-role" className="form-label">{translate(lang, 'userType')}</label>
                                <select name="userType" className="form-control" id="input-user-role" value={data.userType} onChange={(e) => onInputChange(e)} >
                                    {(userRoleData && userRoleData.length)
                                        ?
                                        userRoleData.map((val) => (
                                            <option key={val.id} value={val.role_type}>{val.role_description}</option>
                                        ))
                                        : <></>
                                    }
                                </select>
                            </div>

                            <div className="form-group mb-3 required">
                                <label htmlFor="input-password" className="form-label">{translate(lang, 'password')}</label>
                                <input type="password" id="input-password" className="form-control" name="password" onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />

                                {(error.password)
                                    ?
                                    <label className="text-danger">{error.password}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3 required">
                                <label htmlFor="input-confirm-password" className="form-label">{translate(lang, 'confirmPassword')}</label>
                                <input type="password" id="input-confirm-password" className="form-control" name="confirmPassword" onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />

                                {(error.confirmPassword)
                                    ?
                                    <label className="text-danger">{error.confirmPassword}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3 required">
                                <label htmlFor="input-phone" className="form-label">{translate(lang, 'phone')}</label>
                                <input type="text" id="input-phone" className="form-control" name="phone" value={data.phone} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />

                                {(error.phone)
                                    ?
                                    <label className="text-danger">{error.phone}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="input-email" className="form-label">{translate(lang, 'email')}</label>
                                <input type="text" id="input-email" className="form-control" name="email" value={data.email} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/staff')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(UserForm);