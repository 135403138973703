import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { getMerchantProfileDetail, updateMerchant, getMerchantCategories } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import logo512 from "../../../images/logo512.png";

const MerchantProfile = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const user_id = props.auth.auth.user_id;
    const history = useHistory();

    const [postData, setPostData] = useState({

    });

    const [data, setData] = useState({
        merchant_code: "",
        name: "",
        address: "",
        ori_background_photo: [],
    });

    const [error, setError] = useState({
        selectCategory: ""
    });

    const [file, setFile] = useState({
        logo: [],
        background_photo: [],
    });


    useEffect(() => {
        getMerchantProfile();
        getMerchantCategoryList();
    }, [])

    async function getMerchantProfile() {
        let res = await getMerchantProfileDetail();

        if (res?.status === 'success') {
            setData(res.data);

            let tmp_merchant_cat_data = [];
            if (res && res.data && res.data.categories) {

                res.data.categories.forEach(element => {
                    tmp_merchant_cat_data.push(element.toString());
                });
            }

            if (res.data.bg_image_url) {
                const tmp_bg_image = res.data.bg_image_url.split(",");

                tmp_bg_image.forEach((element, index) => {
                    const tmp_data_image = imagePreview;
                    if (index == 0) {
                        tmp_data_image.first = element;
                    } else if (index == 1) {
                        tmp_data_image.second = element;
                    } else {
                        tmp_data_image.third = element;
                    }
                });
            }

            if (res.data.bg_image) {
                const tmp_bg_image = res.data.bg_image.split(",");
                const tmp_data = res.data;
                tmp_data.ori_background_photo = tmp_bg_image;

                setData({ ...tmp_data });
            }

            setSelectedCategoryData([...tmp_merchant_cat_data]);

        } else {

        }
    }

    const onFileChange = (event) => {
        const name = event.target.name;
        const tmp = file;
        tmp[name] = event.target.files;

        setFile({ ...tmp });
    }

    const submitForm = async () => {
        let check = true;

        let error = {
            selectCategory: "",
        };

        if (selectedCategoryData.length <= 0) {
            check = false;
            error.selectCategory = translate(lang, "errorRequired")
        }

        setError({ ...error });

        if (check) {
            const formData = new FormData();
            // formData.append("name", data.eventName);
            // formData.append("description", data.eventDescription);
            // formData.append("date_start", data.eventDateStart);
            // formData.append("date_end", data.eventDateEnd);
            // formData.append("merchant_id", merchant_id);

            if (file) {
                if (file.logo && file.logo.length > 0) {
                    for (let i = 0; i < file.logo.length; i++) {
                        formData.append("logo", file.logo[i]);
                    }
                }
                if (file.background_photo && file.background_photo.length > 0) {
                    for (let i = 0; i < file.background_photo.length; i++) {
                        formData.append("background_photo", file.background_photo[i]);
                    }
                }
            }

            if (data.ori_background_photo && data.ori_background_photo.length > 0) {
                formData.append("ori_background_photo", data.ori_background_photo);
            }

            if (selectedCategoryData.length > 0) {
                formData.append("categories", selectedCategoryData);
            }

            formData.append("name", data.name);
            formData.append("address", data.address);

            await updateMerchant(formData).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    // history.push('/dashboard');
                    // window.location.replace('/merchant_setting');
                } else {
                    promptMessage(lang, 'error', res.data.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error.message);
            })
        }
    }

    const [merchantCategoryData, setMerchantCategoryData] = useState([]);

    const getMerchantCategoryList = async () => {
        await getMerchantCategories().then((response) => {
            const result = response.data;

            setMerchantCategoryData(result);
        }).catch((err) => {
            // console.log(err);
            // history.push('/merchant')
        })
    }

    const [selectedCategoryData, setSelectedCategoryData] = useState([]);

    const selectCategory = async (e, name) => {
        let tmp_data = selectedCategoryData;

        if (!tmp_data.includes(name)) {
            tmp_data.push(name);
        } else {
            tmp_data = tmp_data.filter(item => item.toString() !== name);
        }

        setSelectedCategoryData([...tmp_data]);
    }

    const config2 = {
        borderRadius: '8px',
        // language: 'en',
        width: '200px',
        height: '200px',
        objectFit: 'contain',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "30px",
        // compressInitial: null,
        // hideDownloadBtn: "true",
        // aspectRatio: "1"
    };

    const [imagePreview, setImagePreview] = useState({
        first: "",
        second: "",
        third: "",
    });

    const handleFileChange = (event, name) => {
        const tmp_data = file;

        tmp_data['background_photo'].push(event.target.files[0]);
        setFile({ ...tmp_data });

        const reader = new FileReader();

        reader.onloadend = () => {
            const tmp_data2 = imagePreview;
            tmp_data2[name] = reader.result;

            setImagePreview({ ...tmp_data2 });
        };

        reader.readAsDataURL(event.target.files[0]);
    };

    const handleUpload = (id) => {
        document.getElementById('background-photo-upload-' + id).click();
    };

    const removeBGImage = (e, no) => {
        e.preventDefault();

        let sortorder = "";

        if (no == '1') {
            sortorder = 'first';
        } else if (no == '2') {
            sortorder = 'second';
        } else if (no == '3') {
            sortorder = 'third';
        }

        const tmp_bg_image = imagePreview;
        tmp_bg_image[sortorder] = "";
        setImagePreview({ ...tmp_bg_image });

        const tmp_data = data;

        let new_bg_image_arr = [];
        if (tmp_data.ori_background_photo && tmp_data.ori_background_photo.length > 0) {
            new_bg_image_arr = tmp_data.ori_background_photo.filter((_, i) => i !== (parseInt(no) - 1));
            tmp_data.ori_background_photo = new_bg_image_arr;

            setData({ ...tmp_data });
        }
    }

    const onInputChange = (event) => {
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, "merchantProfile")}
                    </h3>
                </div>
                <div className="card-body">
                    <div className="profile-row">
                        <div className="form-group row mb-3">
                            <label className="col-sm-2 col-form-label" htmlFor="input-merchant-code">{translate(lang, 'merchantCode')}:</label>
                            <div className="col-sm-10">
                                <input id="input-merchant-code" type="text" className="form-control" value={data.code} readOnly />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-sm-2 col-form-label" htmlFor="input-merchant-name">{translate(lang, 'merchantName')}:</label>
                            <div className="col-sm-10">
                                <input id="input-merchant-name" onChange={(e) => onInputChange(e)} type="text" className="form-control" name="name" value={data.name} />
                            </div>
                        </div>
                        <div className="form-group row mb-3">
                            <label className="col-sm-2 col-form-label" htmlFor="input-merchant-address">{translate(lang, 'merchantAddress')}:</label>
                            <div className="col-sm-10">
                                <textarea id="input-merchant-address" name="address" onChange={(e) => onInputChange(e)} className="form-control" value={data.address} rows={5} ></textarea>
                            </div>
                        </div>
                        <div className="form-group row mb-3 required">
                            <label className="col-sm-2 col-form-label form-label">{translate(lang, 'categoriesLabel')}</label>
                            <div className="col-sm-10">
                                <div className="d-inline-block">
                                    {(merchantCategoryData && merchantCategoryData.length > 0)
                                        ?
                                        merchantCategoryData.map((value) => (
                                            <a key={value.id} className={`btn btn-primary btn-round btn-sm btn-categories ${(selectedCategoryData.includes(value.id.toString())) ? "active" : ""}`} style={{ height: "auto" }} onClick={(e) => { selectCategory(e, `${value.id}`) }}>{value.name}</a>
                                        ))
                                        :
                                        <></>
                                    }
                                </div>
                                {error.selectCategory ? <span className={`text-danger`}>{error.selectCategory}</span> : <></>}
                            </div>
                        </div>
                        <div className="form-group row mb-3 required">
                            <label className="col-sm-2 col-form-label form-label">{translate(lang, 'merchantLogo')}</label>
                            <div className="col-sm-10">
                                <input type="file" name="logo" className="form-control h-auto" accept="image/*" onChange={(e) => onFileChange(e)} />
                                <div className="">
                                    {(file.logo.length == 0 && data.logo_url)
                                        ?
                                        <img src={(data.logo_url) ? data.logo_url : logo512} width={200} height={200} className="w-image-fluid mt-3" />
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group row mb-3 required">
                            <label className="col-sm-2 col-form-label form-label">{translate(lang, 'backgroundPhoto')}</label>
                            <div className="col-sm-10">
                                {/* <input type="file" name="background_photo" className="form-control h-auto" accept="image/*" onChange={(e) => onFileChange(e)} />
                                <div className="">
                                    {(file.background_photo.length == 0 && data.bg_image)
                                        ?
                                        <img src={(data.bg_image) ? data.bg_image : logo512} width={200} height={200} className="w-image-fluid mt-3" />
                                        : <></>
                                    }
                                </div> */}

                                <div className="d-flex">
                                    <div className="mt-2 mx-3 ms-0">
                                        {/* <ReactImagePickerEditor
                                            config={config2}
                                            imageSrcProp={initialImage}
                                            imageChanged={(newDataUri) => { backgoundImageChange(newDataUri) }}
                                        /> */}
                                        {(imagePreview.first)
                                            ?
                                            <div className="position-relative">
                                                <a className="remove-image-a" onClick={(e) => removeBGImage(e, '1')}><i className="fas fa-times"></i></a>
                                                <img src={imagePreview.first} style={config2} />
                                            </div>
                                            :
                                            <>
                                                <input type="file" id="background-photo-upload-1" onChange={(e) => handleFileChange(e, "first")} style={{ display: "none" }} accept="image/*" />
                                                <button onClick={(e) => { handleUpload('1') }} style={config2}><i className="fa fa-camera" aria-hidden="true"></i></button>
                                            </>
                                        }
                                    </div>
                                    <div className="mt-2 mx-3 ms-0">
                                        {(imagePreview.second)
                                            ?
                                            <>
                                                <div className="position-relative">
                                                    <a className="remove-image-a" onClick={(e) => removeBGImage(e, '2')}><i className="fas fa-times"></i></a>
                                                    <img src={imagePreview.second} style={config2} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <input type="file" id="background-photo-upload-2" onChange={(e) => handleFileChange(e, "second")} style={{ display: "none" }} accept="image/*" />
                                                <button onClick={(e) => { handleUpload('2') }} style={config2}><i className="fa fa-camera" aria-hidden="true"></i></button>
                                            </>
                                        }
                                    </div>
                                    <div className="mt-2 mx-3 ms-0">

                                        {(imagePreview.third)
                                            ?
                                            <div className="position-relative">
                                                <a className="remove-image-a" onClick={(e) => removeBGImage(e, '3')}><i className="fas fa-times"></i></a>
                                                <img src={imagePreview.third} style={config2} />
                                            </div>
                                            :
                                            <>
                                                <input type="file" id="background-photo-upload-3" onChange={(e) => handleFileChange(e, "third")} style={{ display: "none" }} accept="image/*" />
                                                <button onClick={(e) => { handleUpload('3') }} style={config2}><i className="fa fa-camera" aria-hidden="true"></i></button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `update`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/dashboard')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(MerchantProfile);