import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Swal from "sweetalert2";

const OrderRedirect = (props) => {
    const lang = props.lang;
    const history = useHistory();
    const query = useLocation().search;
    const payment_status = new URLSearchParams(query);
    const [message, setMessage] = useState("");

    useEffect(async () => {
        Swal.fire({
            title: translate(props.lang, "pleaseWait"),
            onBeforeOpen() {
                Swal.showLoading()
            },
            onAfterClose() {
                Swal.hideLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showCancelButton: false,
            showConfirmButton: false
        })

        const queryParams = new URLSearchParams(window.location.search);
        const paidstatus = queryParams.get('billplz[paid]');

        if (!paidstatus) {
            window.location.replace(`/dashboard`);
            return;
        } else {
            setTimeout(() => {
                window.location.replace(`/order/status?paid=${paidstatus}`);
            }, 2000);
        }

    }, []);

    return (
        <>
            {/* <div className="card">
                <div className="card-body">
                    <h1>{translate(props.lang, "paymentStatus")}</h1>
                    <h5>{translate(props.lang, message)}</h5>
                </div>
            </div> */}
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(OrderRedirect);