import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getUsers, removeUser } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import { Accordion } from "react-bootstrap";

const UserList = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const user_id = props.auth.auth.user_id;
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setFilter] = useState({
        filter_login_id: "",
        filter_name: "",
        filter_phone: "",
        filter_email: "",
        filter_sort_by: "",
        filter_order_by: "",
    });

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });
    };

    const sortOnchange = (event) => {
        const { name } = event.target;

        const tmp = filter;
        if (tmp.filter_order_by && tmp.filter_sort_by == name && tmp.filter_order_by == 'asc') {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = "desc";
        } else {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = 'asc'
        }

        setFilter({ ...tmp });
        setCurrentPage(1);
        getList();
    };

    const submitFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        getList();
    }

    const [data, setData] = useState([]);

    useEffect(() => {
        getList()
    }, [currentPage])

    async function getList() {
        setLoadingData(true);

        let query = {
            // id: merchant_id,
            login_id: filter.filter_login_id,
            name: filter.filter_name,
            phone: filter.filter_phone,
            email: filter.filter_email,
            // status: filter.filter_status,
            page: currentPage,
            sort_by: filter.filter_sort_by,
            order_by: filter.filter_order_by,
        };

        let res = await getUsers(query);

        if (res?.status === 'success') {
            setData(res.data);
        } else {
        }

        setLoadingData(false);
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const pageCount = Math.ceil(data.total_results / 20);

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeUser(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Accordion
                        className="accordion accordion-with-icon"
                        defaultActiveKey="0"
                    >
                        <div className="accordion-item" key={1}>
                            <Accordion.Toggle
                                as={`div`}
                                eventKey={`1`}
                                className={`accordion-header ${activeAccordionWithIcon === 1 ? "" : "collapsed"
                                    }`}
                                onClick={() =>
                                    setActiveAccordionWithIcon(
                                        activeAccordionWithIcon === 1 ? -1 : 1
                                    )
                                }
                            >
                                {/* <span className="accordion-header-icon"></span> */}
                                <span className="accordion-header-text ps-0"><i className="fas fa-filter"></i> {translate(lang, "btnFilter")}</span>
                                <span className="accordion-header-indicator indicator-bordered"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`1`}>
                                <div className="accordion-body-text">
                                    <div className="header-filter my-4">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-login-id">{translate(lang, 'loginID')}</label>
                                                    <input id="filter-login-id" type="text" name="filter_login_id" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'loginID')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-name">{translate(lang, 'name')}</label>
                                                    <input id="filter-name" type="text" name="filter_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'name')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-phone">{translate(lang, 'phone')}</label>
                                                    <input id="filter-phone" type="text" name="filter_phone" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'phone')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="filter-email">{translate(lang, 'email')}</label>
                                                    <input id="filter-email" type="text" name="filter_email" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'email')}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-filter-button text-end">
                                            <button className="btn btn-primary btn-md mt-3" onClick={(e) => submitFilter(e)}>{translate(lang, 'btnFilter')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h3>{translate(lang, "staffList")}</h3>
                        <div className="header-button my-4">
                            {/* <div className="float-start"> */}
                            <Link to="/settings/staff/create" className="btn btn-primary btn-sm">{translate(lang, 'addUser')}</Link>
                            {/* </div> */}
                        </div>
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            <div className="table-responsive">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'login_id') ? filter.filter_order_by : ""} filter_sort`} name="login_id" onClick={(e) => sortOnchange(e)}>{translate(lang, 'loginID')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'display_name') ? filter.filter_order_by : ""} filter_sort`} name="display_name" onClick={(e) => sortOnchange(e)}>{translate(lang, 'name')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'phone') ? filter.filter_order_by : ""} filter_sort`} name="phone" onClick={(e) => sortOnchange(e)}>{translate(lang, 'phone')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'email') ? filter.filter_order_by : ""} filter_sort`} name="email" onClick={(e) => sortOnchange(e)}>{translate(lang, 'email')}</a>
                                            </td>
                                            <td>
                                                {translate(lang, 'action')}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(data && data.results && data.results.length > 0)
                                            ?
                                            data.results.map((value) => (
                                                value.id !== user_id ? (
                                                    <tr key={value.id}>
                                                        <td scope="row">{value.login_id}</td>
                                                        <td scope="row">{value.display_name} <span className="ms-2 label label-danger">{value.user_type}</span></td>
                                                        <td scope="row">{value.phone}</td>
                                                        <td scope="row">{value.email}</td>
                                                        <td scope="row">
                                                            <a className="btn btn-xs btn-primary me-1" href={`/settings/staff/edit/${value.id}`}><i className="fas fa-pencil-alt"></i></a>
                                                            <a className="btn btn-xs me-1" title={translate(lang, "remove")} onClick={() => { removeItem(value.id) }}><i className="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                ) : <></>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={6} className="text-center">{translate(lang, 'noResult')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {(data && data.results && data.results.length > 0)
                                ?
                                <ReactPaginate
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={currentPage - 1}
                                />
                                : <></>}
                        </>
                    }

                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(UserList);