import React from 'react';

// Create Document Component
function PDFTemplate({ data, filter, report_name = "Report", merchant_name = "" }) {

    let restructure_type = '';
    switch (filter.filter_report) {
        case "custody_detail_report_active":
            restructure_type = 'detail';
            break;
        case "custody_detail_report_expired":
            restructure_type = 'detail';
            break;
        case "custody_detail_report_checkout":
            restructure_type = 'detail';
            break;
        case "custody_detail_report_deleted":
            restructure_type = 'detail';
            break;
        case "custody_summary_report_active":
            restructure_type = 'summary';
            break;
        case "custody_summary_report_expired":
            restructure_type = 'summary';
            break;
        case "custody_summary_report_checkout":
            restructure_type = 'summary';
            break;
        case "custody_summary_report_deleted":
            restructure_type = 'summary';
            break;
        case "staff_transaction_report":
            restructure_type = filter.filter_report;
            break;
        case "custody_in_detail_report":
            restructure_type = filter.filter_report;
            break;
        case "custody_in_summary_report":
            restructure_type = filter.filter_report;
            break;
        case "transfer_detail_report":
            restructure_type = filter.filter_report;
            break;
        case "transfer_summary_report":
            restructure_type = filter.filter_report;
            break;

        default:
            break;
    }

    return (
        <div className='pdf-template-div'>
            <div className='pdf-header'>
                <div className='d-flex align-content-space-between justify-content-space-between'>
                    <span><strong>{merchant_name}</strong></span>
                    <span><strong>From date: {filter.filter_date_start} to {filter.filter_date_end}</strong></span>
                    <span className='text-capitalize'><strong>{report_name}</strong></span>
                </div>
            </div>
            <div className='pdf-body'>
                {(restructure_type != 'staff_transaction_report')
                    ?
                    <>
                        {(data && data.length > 0)
                            ?
                            data.map((value, index) => (
                                <div className='report-div pt-2' key={index + 1}>
                                    {/* custody_detail_report_active */}
                                    {/* custody_detail_report_expired */}
                                    {/* custody_detail_report_checkout */}
                                    {/* custody_summary_report_active */}
                                    {/* custody_summary_report_expired */}
                                    {/* custody_summary_report_checkout */}
                                    {(restructure_type == 'summary' || restructure_type == 'detail')
                                        ?
                                        <>
                                            <div>Category: <strong>{value.category}</strong></div>
                                            <div className='table-responsive'>
                                                <table className='report-table table mb-4'>
                                                    <thead>
                                                        {(restructure_type == 'detail')
                                                            ?
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Custody Item</th>
                                                                <th>Customer</th>
                                                                <th>Phone</th>
                                                                <th>Custody No</th>
                                                                <th className='text-end'>Transaction Date</th>
                                                                <th className='text-end'>Expiry Date</th>
                                                                <th className='text-end'>Quantity</th>
                                                                {(filter.filter_report == 'custody_detail_report_deleted')
                                                                    ?
                                                                    <th>Bartender</th>
                                                                    : <></>
                                                                }
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>Custody Item</th>
                                                                <th className='text-end'>Balance</th>
                                                            </tr>
                                                        }

                                                    </thead>
                                                    {(restructure_type == 'detail')
                                                        ?
                                                        <tbody>
                                                            {(value.items && value.items.length > 0)
                                                                ?
                                                                value.items.map((val, ind) => (

                                                                    <tr key={ind + 1}>

                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.prod_desc}</td>
                                                                        <td>{val.customer_name}</td>
                                                                        <td>{val.customer_phone}</td>
                                                                        <td>{val.code}</td>
                                                                        <td className='text-end'>{val.custody_date}</td>
                                                                        <td className='text-capitalize text-end'>{(val.expiry_type == 'never expired') ? val.expiry_type : val.expiry_date}</td>
                                                                        <td className='text-end'>{val.net_balance}</td>
                                                                        {(filter.filter_report == 'custody_detail_report_deleted')
                                                                            ?
                                                                            <td>{val.bartender}</td>
                                                                            : <></>
                                                                        }
                                                                    </tr>
                                                                ))
                                                                : <></>
                                                            }
                                                        </tbody>
                                                        :
                                                        <tbody>
                                                            {(value.items && value.items.length > 0)
                                                                ?
                                                                value.items.map((val, ind) => (

                                                                    <tr key={ind + 1}>

                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.product}</td>
                                                                        <td className='text-end'>{val.total}</td>
                                                                    </tr>
                                                                ))
                                                                : <></>
                                                            }
                                                        </tbody>
                                                    }

                                                    <tfoot>
                                                        {(restructure_type == 'detail')
                                                            ?
                                                            <tr>
                                                                <th className='text-end' colSpan={7}>Total </th>
                                                                <th className='text-end'>{value.total_quantity}</th>
                                                            </tr>
                                                            :
                                                            <tr>
                                                                <th className='text-end' colSpan={2}>Total </th>
                                                                <th className='text-end'>{value.total}</th>
                                                            </tr>
                                                        }
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {/* custody_detail_report_active */}
                                    {/* custody_detail_report_expired */}
                                    {/* custody_detail_report_checkout */}
                                    {/* custody_summary_report_active */}
                                    {/* custody_summary_report_expired */}
                                    {/* custody_summary_report_checkout */}


                                    {/* custody_in_detail_report */}
                                    {(restructure_type == 'custody_in_detail_report')
                                        ?
                                        <>
                                            <div>Category: <strong>{value.category}</strong></div>
                                            <div className='table-responsive'>
                                                <table className='report-table table mb-4'>
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Custody Item</th>
                                                            <th>Customer</th>
                                                            <th>Cust. Ref</th>
                                                            <th>Phone</th>
                                                            <th>Remark</th>
                                                            <th>Custody No</th>
                                                            <th className='text-end'>Custody Date</th>
                                                            <th className='text-end'>Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(value.items && value.items.length > 0)
                                                            ?
                                                            value.items.map((val, ind) => (
                                                                <>
                                                                    <tr key={ind + 1}>
                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.prod_desc}</td>
                                                                        <td>{val.customer_name}</td>
                                                                        <td>{val.customer_reference}</td>
                                                                        <td>{val.customer_phone}</td>
                                                                        <td>{val.remark}</td>
                                                                        <td>{val.code}</td>
                                                                        <td className='text-end'>{val.custody_date}</td>
                                                                        <td className='text-end'>{val.net_balance}</td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                            : <></>
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='text-end' colSpan={8}>Total</th>
                                                            <th className='text-end'>{value.total_quantity}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {/* custody_in_detail_report */}

                                    {/* custody_in_summary_report */}
                                    {/* transfer_summary_report */}
                                    {(restructure_type == 'custody_in_summary_report' || restructure_type == 'transfer_summary_report')
                                        ?
                                        <>
                                            <div>Category: <strong>{value.category}</strong></div>
                                            <div className='table-responsive'>
                                                <table className='report-table table mb-4'>
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Custody Item</th>
                                                            <th className='text-end'>
                                                                {(restructure_type == 'custody_in_summary_report')
                                                                    ?
                                                                    <>
                                                                        Balance
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Quantity
                                                                    </>
                                                                }
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(value.items && value.items.length > 0)
                                                            ?
                                                            value.items.map((val, ind) => (
                                                                <>
                                                                    <tr key={ind + 1}>
                                                                        <td>{ind + 1}</td>
                                                                        <td>{val.product}</td>
                                                                        <td className='text-end'>{val.total}</td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                            : <></>
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='text-end' colSpan={2}>Total</th>
                                                            <th className='text-end'>{value.total}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {/* custody_in_summary_report */}
                                    {/* transfer_summary_report */}

                                    {/* transfer_detail_report */}
                                    {(restructure_type == 'transfer_detail_report')
                                        ?
                                        <>
                                            <div>Category: <strong>{value.category}</strong></div>
                                            <div className='table-responsive'>
                                                <table className='report-table table mb-4'>
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th className='text-end'>Date Time</th>
                                                            <th>Item</th>
                                                            <th>Custody No</th>
                                                            <th>Cust. Ref</th>
                                                            <th>Customer</th>
                                                            <th>Phone</th>
                                                            <th>Remark</th>
                                                            <th className='text-end'>Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(value.items && value.items.length > 0)
                                                            ?
                                                            value.items.map((val, ind) => (
                                                                <>
                                                                    <tr key={ind + 1}>
                                                                        <td>{ind + 1}</td>
                                                                        <td className='text-end'>{val.created_date}</td>
                                                                        <td>{val.prod_desc}</td>
                                                                        <td>{val.code}</td>
                                                                        <td>{val.customer_reference}</td>
                                                                        <td>{val.customer_name}</td>
                                                                        <td>{val.customer_phone}</td>
                                                                        <td>{val.remark}</td>
                                                                        <td className='text-end'>{val.net_balance}</td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                            : <></>
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className='text-end' colSpan={8}>Total</th>
                                                            <th className='text-end'>{value.total_quantity}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    {/* transfer_detail_report */}
                                </div>
                            ))
                            :
                            <></>
                        }
                    </>
                    :
                    <></>
                }

                {/* staff_transaction_report */}
                {(restructure_type == 'staff_transaction_report')
                    ?
                    <>
                        <div className='table-responsive'>
                            <table className='report-table table mb-4'>
                                <thead>
                                    {/* <th>No.</th> */}
                                    <th className='text-end'>Date Time</th>
                                    <th>Updated By</th>
                                    <th>Code</th>
                                    <th>Type</th>
                                    <th>Custody</th>
                                    <th>Details</th>
                                    <th>Bartender</th>
                                </thead>
                                <tbody>
                                    {(data && data.length > 0)
                                        ?
                                        data.map((value, index) => (
                                            <tr key={index + 1}>
                                                <td className='text-end'>{value.updated_date}</td>
                                                <td>{value.updated_by_info.display_name}</td>
                                                <td>{value.code}</td>
                                                <td>
                                                    {(value.rec_type == 0 || value.status == 'Deleted')
                                                        ?
                                                        <>Deleted</>
                                                        :
                                                        <>{value.type} {value.description}</>
                                                    }
                                                </td>
                                                <td>
                                                    <span>
                                                        {(value.net_balance > 0)
                                                            ?
                                                            <>+{value.net_balance}</>
                                                            :
                                                            value.net_balance
                                                        }
                                                        &nbsp;{value.prod_uom_desc} {value.prod_desc}
                                                    </span>
                                                </td>
                                                <td>
                                                    {value.customer_name} - {value.customer_phone}
                                                    <br />
                                                    Custody On {value.custody_date}
                                                    <br />
                                                    <span className='text-capitalize'>{value.expiry_type}</span>
                                                </td>
                                                <td>{value.bartender}</td>
                                            </tr>
                                        ))
                                        : <></>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <></>
                }
                {/* staff_transaction_report */}
            </div>
        </div>
    )
}

export default PDFTemplate;
