import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getPackages, createOrder, changePackage, merchantTopup, getSMSCredit, merchantTopupSMSCredit, merchantTopupSMSCreditByMerchantCredit } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Swal from "sweetalert2";
import { Table } from "reactstrap";

const CreateSubscription = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();

    const [packageData, setPackageData] = useState([]);

    useEffect(() => {
        getPackageList();
        getSMSCreditDetail();
    }, [])


    const [smsCredit, setSMSCredit] = useState({});
    const [smsCreditText, setSMSCreditText] = useState("");

    async function getPackageList() {
        let res = await getSMSCredit();

        if (res?.status === 'success') {
            setSMSCredit(res.data);
        }
    }

    async function getSMSCreditDetail() {
        let res = await getPackages();

        if (res?.status === 'success') {
            setPackageData(res.data);
        }
    }

    const purchasePackage = async (event, package_id) => {
        setLoadingData(true);
        let form_data = { package_id };

        await createOrder(form_data).then((res) => {
            if (res.status === 'success') {
                const result = res.data.responseData;
                promptMessage(lang, 'success', res.message)

                // redirect to payment gateway
                window.location.href = result.url;

            } else {
                promptMessage(lang, 'error', res.message)
            }
        }).catch((error) => {
            promptMessage(lang, 'error', error);
        })
        setLoadingData(false);
    };

    const changePackageFunc = async (event, package_id) => {
        setLoadingData(true);
        let form_data = { package_id };

        Swal.fire({
            title: translate(props.lang, "changePackageConfirmation"),
            text: translate(props.lang, "changePackageMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await changePackage(form_data).then((res) => {
                    if (res.status === 'success') {
                        // const result = res.data.responseData;
                        promptMessage(lang, 'success', res.message)
                        getPackageList();
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        })

        setLoadingData(false);
    };

    const [loadingData, setLoadingData] = useState(false);
    const [topupAmount, setTopupAmount] = useState({
        "merchant_credit": "",
        "sms_credit": "",
    });

    const inputTopupAmount = (name, amount) => {
        const tmp_data = topupAmount;
        tmp_data[name] = amount;

        setTopupAmount({ ...tmp_data });
    }

    const inputOnChange = (event, name) => {
        const tmp_data = topupAmount;
        tmp_data[name] = parseInt(event.target.value).toFixed(0);

        setTopupAmount({ ...tmp_data });

        let total_text = "";
        if (parseInt(event.target.value) > 0) {
            total_text = `Total Credits: ${(parseInt(event.target.value).toFixed(0) * 5)}`;
        }

        setSMSCreditText(total_text);
    }

    const [error, setError] = useState({
        topup_amount: "",
        sms_amount: ""
    });

    const topupFunc = async () => {
        setLoadingData(true);
        let check = true;

        let error = {
            topup_amount: "",
        }

        if (!topupAmount.merchant_credit || topupAmount.merchant_credit < 200) {
            check = false;
            error.topup_amount = translate(lang, "errorMinTopup").replace("%s", "RM 200");
        }

        setError({ ...error });

        if (check) {
            let form_data = {
                topup_amount: topupAmount.merchant_credit
            };

            await merchantTopup(form_data).then((res) => {
                if (res.status === 'success') {
                    const result = res.data.responseData;
                    setLoadingData(true);
                    // promptMessage(lang, 'info', res.message)

                    // redirect to payment gateway
                    window.location.href = result.url;

                    setLoadingData(false);
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }

        setLoadingData(false);
    };

    const topupSMSCreditFunc = async () => {
        setLoadingData(true);
        let check = true;

        let error = {
            sms_amount: "",
        }

        if (!topupAmount.sms_credit || topupAmount.sms_credit < 10) {
            check = false;
            error.sms_amount = translate(lang, "errorMinTopup").replace("%s", "RM 10");
        }

        setError({ ...error });

        if (check) {
            let form_data = {
                topup_amount: topupAmount.sms_credit
            };

            await merchantTopupSMSCredit(form_data).then((res) => {
                if (res.status === 'success') {
                    const result = res.data.responseData;
                    setLoadingData(true);
                    // promptMessage(lang, 'info', res.message)

                    // redirect to payment gateway
                    window.location.href = result.url;

                    setLoadingData(false);
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }

        setLoadingData(false);
    };

    const topupSMSCreditFunc2 = async () => {
        setLoadingData(true);
        let check = true;

        let error = {
            sms_amount: "",
        }

        if (!topupAmount.sms_credit || topupAmount.sms_credit < 10) {
            check = false;
            error.sms_amount = translate(lang, "errorMinTopup").replace("%s", "RM 10");
        }

        setError({ ...error });

        if (check) {
            let form_data = {
                topup_amount: topupAmount.sms_credit
            };

            await merchantTopupSMSCreditByMerchantCredit(form_data).then((res) => {
                if (res.status === 'success') {
                    const result = res.data;
                    setLoadingData(true);
                    promptMessage(lang, 'success', res.message);
                    getPackageList();
                    getSMSCreditDetail();
                    // window.location.reload();
                    setLoadingData(false);
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }

        setLoadingData(false);
    };

    return (
        <>
            {(loadingData) ?
                <>
                    <div className="loader-div loader-lg-div"><i className="fas fa-spinner fa-spin"></i></div>
                </>
                :
                <></>
            }
            <div className="card row">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, "subscription")}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column">
                    <div className="row mb-3 text-center">
                        {(packageData && packageData.results && packageData.results.length > 0)
                            ?
                            packageData.results.map((value) => (
                                <>
                                    <div className="col-lg-4 col-md-6" style={{ marginBottom: 20 }}>
                                        <div className={"d-flex flex-column package-card package " + (packageData?.merchant_info?.package == value?.code ? "package-active" : "")}>
                                            <div className="pricing-card-header">
                                                <h4>{value.description}</h4>
                                                <small className="text-danger discount-text">{value.discount}</small>
                                            </div>
                                            <div className="pricing-card-body pt-2">
                                                {/* <h5><strong>RM {parseFloat(value.total).toFixed(0)}</strong> / {value.expiry_unit} {value.expiry_selection}</h5> */}
                                                <div className="text-left text-white">
                                                    {(value.json && value.json.length > 0)
                                                        ?
                                                        value.json.map((val) => (
                                                            <>
                                                                <hr />
                                                                <p className="text-capitalize">{val}</p>
                                                            </>
                                                        ))
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className="pricing-card-footer">
                                                {(packageData && packageData.merchant_info && packageData.merchant_info.status != 'Expired')
                                                    ?
                                                    <>
                                                        {(packageData.merchant_info.package == value.code)
                                                            ?
                                                            <>
                                                                {/* <button className="btn btn-outline-primary btn-block" disabled>{translate(lang, "activated")}</button> */}
                                                                <div className="position-absolube" style={{ color: "#A400FF" }}>{translate(lang, "activated")}</div>
                                                            </>
                                                            :
                                                            <>
                                                                {/* <button className="btn btn-primary btn-block" onClick={(e) => changePackageFunc(e, value.id)}>{translate(lang, "activate")}</button> */}

                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <button className="btn btn-primary btn-block" onClick={(e) => purchasePackage(e, value.id)}>{translate(lang, "select")}</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                            :
                            <></>
                        }
                    </div>
                </div>
                {/* <div className="card-footer">
                    <div className="d-flex flex-row">

                    </div>
                </div> */}
            </div>

            {(packageData && packageData.merchant_info && packageData.merchant_info.status != 'Expired')
                ?
                <>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                <i className="fas fa-wallet"></i> {translate(lang, "topupMerchantCredit")}
                            </h3>
                            <small className="text-white">
                                {translate(lang, "currentMerchantCredit")}: {parseFloat(packageData.merchant_info.wallet_balance).toFixed(2)}
                            </small>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="form-group mb-3 required">
                                        <label className="form-label">{translate(lang, "amount")}</label>
                                        <input type="number" name="topup_amount" className="form-control" value={topupAmount.merchant_credit} onChange={(e) => inputOnChange(e, "merchant_credit")} />

                                        {(error.topup_amount)
                                            ?
                                            <>
                                                <label className="text-danger">{error.topup_amount}</label>
                                            </>
                                            :
                                            <></>
                                        }

                                        <div className="amount-selection-div mt-3">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("merchant_credit", 200)}>
                                                        RM 200
                                                    </a>
                                                </div>
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("merchant_credit", 500)}>
                                                        RM 500
                                                    </a>
                                                </div>
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("merchant_credit", 1000)}>
                                                        RM 1000
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mt-4">
                                        <button className="btn btn-primary btn-sm" onClick={(e) => topupFunc()}>{translate(lang, "topup")}</button>
                                    </div>
                                </div>
                                <div className="col-sm-7">

                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <td className="text-end">{translate(lang, "dateTime")}</td>
                                                <td className="text-end">{translate(lang, "amount")}</td>
                                                <td>{translate(lang, "remark")}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(packageData && packageData.merchant_transactions && packageData.merchant_transactions.length > 0)
                                                ?
                                                packageData.merchant_transactions.map((value) => (
                                                    <tr key={value.id}>
                                                        <td className="text-end">
                                                            {(value.created_date)
                                                                ?
                                                                moment.utc(value.custody_date).utcOffset(8).format(translate(lang, 'date_time_format'))
                                                                :
                                                                ""
                                                            }
                                                        </td>
                                                        <td className="text-end">
                                                            {parseFloat(value.amount).toFixed(2)}
                                                        </td>
                                                        <td>{value.remark}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={3} className="text-center">{translate(lang, 'noResult')}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">

                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                <i className="fas fa-sms"></i> {translate(lang, "topupSMSCredit")}
                            </h3>
                            <small className="text-white">
                                {translate(lang, "currentSMSCredit")}: {parseInt(smsCredit)}
                            </small>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-3 required">
                                        <label className="form-label">{translate(lang, "amount")}</label>
                                        <input type="number" name="topup_sms_amount" className="form-control" value={topupAmount.sms_credit} onChange={(e) => inputOnChange(e, "sms_credit")} />
                                        <small className="d-block">
                                            {translate(lang, "topupSMSCreditInfo")}
                                        </small>
                                        {(error.sms_amount)
                                            ?
                                            <>
                                                <label className="text-danger">{error.sms_amount}</label>
                                            </>
                                            :
                                            <></>
                                        }

                                        <div className="amount-selection-div mt-3">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("sms_credit", 10)}>
                                                        RM 10
                                                    </a>
                                                </div>
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("sms_credit", 20)}>
                                                        RM 20
                                                    </a>
                                                </div>
                                                <div className="col-sm-4">
                                                    <a className="btn btn-block btn-outline-primary" onClick={(e) => inputTopupAmount("sms_credit", 50)}>
                                                        RM 50
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mt-4">
                                        {(smsCreditText)
                                            ?
                                            <p className="text-white">{smsCreditText}</p>
                                            : <></>
                                        }
                                        <button className="btn btn-primary btn-sm me-2" onClick={(e) => topupSMSCreditFunc()}>{translate(lang, "onlinePayment")}</button>
                                        <button className="btn btn-primary btn-sm" onClick={(e) => topupSMSCreditFunc2()}>{translate(lang, "payByMerchantCredit")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">

                            </div>
                        </div>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CreateSubscription);