import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getRedeemVoucherDetail, getRedeemVoucher } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Swal from "sweetalert2";

const RedeemVoucher = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    const history = useHistory();
    const [userRoleData, setUserRoleData] = useState([]);
    const [data, setData] = useState({});

    const [error, setError] = useState({
    });

    const [loadingData, setLoadingData] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid');

    useEffect(() => {
        if (!uid) {
            history.push('/dashboard');
        } else {
            getDetails(uid);
        }
    }, [])

    const getDetails = async (slug) => {
        setLoadingData(true);
        let params = { token: slug };
        let res = await getRedeemVoucherDetail(params);

        if (res.status === 'success') {
            // promptMessage(lang, 'success', res.message);
            setData(res.data);
        } else {
            promptMessage(lang, 'error', res.message);
            history.push("/dashboard");
        }
        setLoadingData(false);
    }

    function redeemNow() {
        Swal.fire({
            title: translate(props.lang, "redeemVoucherConfirmation"),
            text: translate(props.lang, "redeemVoucherMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let params = { token: uid };
                let res = await getRedeemVoucher(params);

                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    history.push("/voucher");
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <h3 className="float-start">{translate(lang, "redeemVoucher")}</h3>
                        <div className="float-end">
                            <a href="#" className="btn btn-sm btn-primary" onClick={(e) => redeemNow(e)}>{translate(lang, "redeem")}</a>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            {(data)
                                ?
                                <div className="row pt-3">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-3">
                                            <h4>{translate(lang, "customerInfo")}</h4>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <label>{translate(lang, "name")}</label>
                                                    <p className="text-white">{data.customer_name}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>{translate(lang, "phone")}</label>
                                                    <p className="text-white">{data.phonecode}{data.phonenumber}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label>{translate(lang, "email")}</label>
                                                    <p className="text-white">{data.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h4>{translate(lang, "voucherDetail")}</h4>
                                            {(data.redeems && data.redeems.length > 0)
                                                ?
                                                data.redeems.map((item) => (
                                                    <div className="col-sm-6 mt-3" key={item.id}>
                                                        <div className="d-sm-flex">
                                                            <div className="voucher-name">
                                                                <div style={{ color: '#dddddd', fontSize: 24, marginBottom: 10 }}>{item.code}</div>
                                                                <div style={{ color: '#dddddd', }}>{item.title}</div>
                                                                <div style={{ color: '#dddddd', marginBottom: 15 }}>{item.description}</div>
                                                                <div style={{  }}>Terms &amp; Conditions</div>
                                                                <div style={{ color: '#dddddd', whiteSpace: 'pre-wrap' }}>{item.terms_and_conditions}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(RedeemVoucher);