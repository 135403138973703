/// Pages

import Dashboard from "../jsx/pages/Dashboard";
import EventList from "../jsx/pages/event/EventList";
import CreateEvent from "../jsx/pages/event/CreateEvent";
import CustodyList from "../jsx/pages/custody/CustodyList";
import ProductList from "../jsx/pages/product/ProductList";
import CreateProduct from "../jsx/pages/product/CreateProduct";
import ProductTemplate from "../jsx/pages/product/ProductTemplate";
import Profile from "../jsx/pages/profile/Profile";
import Custody from "../jsx/pages/custody/SelectCustody";
import CreateCustody from "../jsx/pages/custody/CreateCustody";
import CreateCustody2 from "../jsx/pages/custody/CreateCustody2";
import CustomerList from "../jsx/pages/customer/CustomerList";
import CreateCustomer from "../jsx/pages/customer/CreateCustomer";
import Report from "../jsx/pages/report/Report";
import MerchantProfile from "../jsx/pages/settings/MerchantProfile";
import UserList from "../jsx/pages/settings/UserList";
import UserForm from "../jsx/pages/settings/UserForm";
import CategoryList from "../jsx/pages/settings/CategoryList";
import CategoryForm from "../jsx/pages/settings/CategoryForm";
import CategoryList2 from "../jsx/pages/settings/CategoryList2";
import CategoryForm2 from "../jsx/pages/settings/CategoryForm2";
import UOMList from "../jsx/pages/settings/UOMList";
import UOMForm from "../jsx/pages/settings/UOMForm";
import MISCForm from "../jsx/pages/settings/MISCForm";
import CreateSubscription from "../jsx/pages/subscription/CreateSubscription";
import OrderRedirect from "../jsx/pages/order/orderRedirect";
import OrderStatus from "../jsx/pages/order/orderStatus";
import SMSList from "../jsx/pages/settings/SMSList";
import SMSForm from "../jsx/pages/settings/SMSForm";
import RedeemCustody from "../jsx/pages/custody/RedeemCustody";
import RedeemVoucher from "../jsx/pages/voucher/RedeemVoucher";
import LiveBandList from "../jsx/pages/live_band/LiveBandList";
import CreateLiveBand from "../jsx/pages/live_band/CreateLiveBand";
import VoucherList from "../jsx/pages/voucher/VoucherList";

const routes = [
    /// Dashboard
    { url: "", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "dashboard", component: Dashboard, regex: 'dashboard', name: "dashboard" },
    { url: "custody", component: Custody, regex: 'custody', name: "custody" },
    { url: "custody/:type/:slug?", component: CreateCustody, regex: 'custody', name: "custody" },
    { url: "create_custody", component: CreateCustody2, regex: 'custody', name: "custody" },
    { url: "custody_list", component: CustodyList, regex: 'custody', name: "custodyList" },
    { url: "event", component: EventList, regex: 'event', name: "eventList" },
    { url: "event/:type/:slug?", component: CreateEvent, regex: 'event', name: "event" },
    { url: "voucher", component: VoucherList, regex: 'voucher', name: "voucherList" },
    { url: "product", component: ProductList, regex: 'product', name: "productList" },
    { url: "product/:type/:slug?", component: CreateProduct, regex: 'product', name: "product" },
    { url: "product_template", component: ProductTemplate, regex: 'productTemplate', name: "productTemplateList" },
    { url: "settings/profile", component: Profile, regex: 'profile', name: "profile" },
    { url: "customer", component: CustomerList, regex: 'customer', name: "customer" },
    { url: "customer/:type/:slug?", component: CreateCustomer, regex: 'customer', name: "customer" },
    { url: "report", component: Report, regex: 'report', name: "report" },
    { url: "merchant_setting", component: MerchantProfile, regex: 'merchant', name: "merchant" },
    { url: "settings/staff", component: UserList, regex: 'staff', name: "staff" },
    { url: "settings/staff/:type/:slug?", component: UserForm, regex: 'staff', name: "staff" },
    { url: "settings/category_1", component: CategoryList, regex: 'category', name: "category" },
    { url: "settings/category_1/:type/:slug?", component: CategoryForm, regex: 'category', name: "category" },
    { url: "settings/category_2", component: CategoryList2, regex: 'category', name: "category" },
    { url: "settings/category_2/:type/:slug?", component: CategoryForm2, regex: 'category', name: "category" },
    { url: "settings/uom", component: UOMList, regex: 'uom', name: "uom" },
    { url: "settings/uom/:type/:slug?", component: UOMForm, regex: 'uom', name: "uom" },
    { url: "settings/sms", component: SMSList, regex: 'sms', name: "sms" },
    { url: "settings/sms/:type/:slug?", component: SMSForm, regex: 'sms', name: "sms" },
    { url: "settings/misc", component: MISCForm, regex: 'misc', name: "misc" },
    { url: "settings/subscription", component: CreateSubscription, regex: 'subscription', name: "subscription" },
    { url: "order/payment", component: OrderRedirect, regex: 'order', name: "order" },
    { url: "order/status", component: OrderStatus, regex: 'order', name: "order" },
    { url: "redeem", component: RedeemCustody, regex: 'custody', name: "custody" },
    { url: "redeemVoucher", component: RedeemVoucher, regex: 'voucher', name: "voucher" },
    { url: "live_band", component: LiveBandList, regex: 'liveBand', name: "liveBand" },
    { url: "live_band/:type/:slug?", component: CreateLiveBand, regex: 'liveBand', name: "liveBand" },
];

export default routes;