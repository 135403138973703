import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getSMS, editSMS } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";

const SMSForm = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [userRoleData, setUserRoleData] = useState([]);
    const [data, setData] = useState({
        text: "",
        type: "",
    });

    const [error, setError] = useState({
        text: "",
        type: "",
    });

    useEffect(() => {
        if (type === 'edit') {
            if (!slug) {
                history.push('/settings/sms');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        // let params = { id: slug };
        let res = await getSMS(slug);

        setData({
            text: "",
            type: "",
        });

        if (res.status === 'success') {
            let data = res.data;

            setData({
                text: (data.text) ? data.text : "",
                type: (data.type) ? data.type : "",
            });

        } else {
            history.push('/settings/sms');
        }
    }

    const submitForm = async () => {
        let check = true;
        let error = {
            text: "",
            type: "",
        }

        if (!data.text) {
            check = false;
            error.text = translate(lang, "errorRequired");
        }

        if (!data.type) {
            check = false;
            error.type = translate(lang, "errorRequired");
        }

        setError({ ...error });
        if (check) {
            const formData = {
                "text": data.text,
                "type": data.type
            }

            if (type == 'edit') {
                await editSMS(formData, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/sms');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "edit" ? "editCategory" : "createCategory"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group mb-3">
                                <label className="form-label">{translate(lang, "formatter")}</label>
                                <div>
                                    Code: {translate(lang, "{code}")} *After custody created, automatically appear in SMS
                                    <br />
                                    Customer Name: {translate(lang, "{cust_name}")}
                                    <br />
                                    Expiry Date: {translate(lang, "{exp_date}")}
                                    <br />
                                    Company Name: {translate(lang, "{com_name}")}
                                    <br />
                                    Product: {translate(lang, "{prod}")}
                                    <br />
                                    Balance: {translate(lang, "{balance}")}
                                    <br />
                                    Custody Date: {translate(lang, "{custody_date}")}
                                    <br />
                                    Remark 1: {translate(lang, "{remark1}")}
                                    <br />
                                    Remark 2: {translate(lang, "{remark2}")}
                                    <br />
                                    Remark 3: {translate(lang, "{remark3}")}
                                    <br />
                                    Out Quantity: {translate(lang, "{out_quantity}")}
                                    <br />
                                    New Expiry Date: {translate(lang, "{new_exp_date}")}
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="input-type" className="form-label">{translate(lang, 'type')}</label>
                                <input id="input-type" type="text" className="form-control" name="type" value={data.type} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} disabled />
                                {(error.type)
                                    ?
                                    <label className="text-danger">{error.type}</label>
                                    : <></>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="input-text" className="form-label">{translate(lang, 'text')}</label>
                                <textarea id="input-text" type="text" className="form-control" name="text" value={data.text} rows={10} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')}></textarea>
                                {(error.text)
                                    ?
                                    <label className="text-danger">{error.text}</label>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/sms')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(SMSForm);