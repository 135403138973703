import { basicAxiosInstance, noTokenAxiosInstance } from '../services/AxiosInstance';
import { url } from '../utils/api';

export async function getUserList(params) {
    return basicAxiosInstance.get(`/customers/get_customers`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUser(body) {
    return basicAxiosInstance.post(`/users/`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createAdmin(body) {
    return basicAxiosInstance.post(`/users/admin`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

// export async function removeUser(id) {
//     return basicAxiosInstance.put(`/users/${id}/remove`).then((response) => {
//         return response.data;
//     }).catch((err) => {
//         return err.response ?? err;
//     });
// }

export async function removeAgent(id) {
    return basicAxiosInstance.get(`/users/admins/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getMasterPolicyList(params) {
    return basicAxiosInstance.get(`/policy/master`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getPolicyCategoryList(params) {
    return basicAxiosInstance.get(`/policy/category`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMasterPolicy(body) {
    return basicAxiosInstance.post('/policy/master', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editMasterPolicy(body, id) {
    return basicAxiosInstance.put(`/policy/master/${id}`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeMasterPolicy(id) {
    return basicAxiosInstance.put(`/policy/master/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createPolicyCategory(body) {
    return basicAxiosInstance.post('/policy/category', body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editPolicyCategory(body, id) {
    return basicAxiosInstance.put(`/policy/category/${id}`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removePolicyCategory(id) {
    return basicAxiosInstance.put(`/policy/category/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getMerchantList(params) {
    return noTokenAxiosInstance.get(`/merchant`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getUserPolicyList(params) {
    return basicAxiosInstance.get(`/policy`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getRiderList(params) {
    return basicAxiosInstance.get(`/policy/riders`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createRider(body) {
    return basicAxiosInstance.post('/policy/riders', body, {
        headers: { "Content-Type": "multipart/form-data;" },
    })
        .then((response) => {
            return response.data;
        }).catch((err) => {
            return err.response ?? err;
        })
}

export async function editRider(body, id) {
    return basicAxiosInstance.put(`/policy/riders/${id}`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    })
        .then((response) => {
            return response.data;
        }).catch((err) => {
            return err.response ?? err;
        })
}

export async function removeRider(id) {
    return basicAxiosInstance.put(`/policy/riders/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createMemberPolicy(body) {
    return basicAxiosInstance.post(`/policy/`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editMemberPolicy(body, id) {
    return basicAxiosInstance.put(`/policy/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeMemberPolicy(id) {
    return basicAxiosInstance.put(`/policy/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createMerchant(body) {
    return basicAxiosInstance.post(`/merchant/`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editMerchant(body, id) {
    return basicAxiosInstance.put(`/merchant/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeMerchant(id) {
    return basicAxiosInstance.put(`/merchant/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getMerchantPolicyList(params) {
    return basicAxiosInstance.get(`/merchant/policy`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createMerchantPolicy(body) {
    return basicAxiosInstance.post(`/merchant/policy`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editMerchantPolicy(body, id) {
    return basicAxiosInstance.put(`/merchant/policy/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeMerchantPolicy(id) {
    return basicAxiosInstance.put(`/merchant/policy/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updatePolicyQuestionnaire(body, id) {
    return basicAxiosInstance.put(`/policy/questionnaire/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

// Brewhub Start
export async function createEvent(body) {
    return basicAxiosInstance.post('/events/create', body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editEvent(body, event_id) {
    return basicAxiosInstance.post(`/events/edit/${event_id}`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getEvent(id) {
    return basicAxiosInstance.get(`/events/merchant_get_event/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getEventList(params) {
    return basicAxiosInstance.get(`/events/merchant_get_events`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getVoucherList(params) {
    return basicAxiosInstance.get(`/vouchers/merchant_get_vouchers`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustodyList(params) {
    return basicAxiosInstance.get(`/custody/merchant_get_custodies`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustomerAutoComplete(params) {
    return basicAxiosInstance.get(`/customers/autocomplete`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustomerOnlyAutoComplete(params) {
    return basicAxiosInstance.get(`/customers/customer_only_autocomplete`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustodyAutoComplete(params) {
    return basicAxiosInstance.get(`/custody/autocomplete`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProductList(params) {
    return basicAxiosInstance.get(`/product/merchant_get_products`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProductTemplateList(params) {
    return basicAxiosInstance.get(`/product/merchant_get_template_products`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategoryList(params) {
    return basicAxiosInstance.get(`/product/merchant_get_categories`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategoryList2(params) {
    return basicAxiosInstance.get(`/product/merchant_get_categories2`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProductUOMList(params) {
    return basicAxiosInstance.get(`/product/merchant_get_uom`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProduct(id) {
    return basicAxiosInstance.get(`/product/merchant_get_product/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProductTemplate(id) {
    return basicAxiosInstance.get(`/product/merchant_get_template_product/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editProduct(body, product_id) {
    return basicAxiosInstance.post(`/product/merchant_edit_product/${product_id}`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createProduct(body) {
    return basicAxiosInstance.post('/product/merchant_add_product', body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function addProductRapid(body) {
    return basicAxiosInstance.post(`/product/merchant_add_product_rapid`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getProfileDetail() {
    return basicAxiosInstance.get(`/profile`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createCustody(body) {
    return basicAxiosInstance.post(`/custody/merchant_create_custody`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createCustody2(body) {
    return basicAxiosInstance.post(`/custody/merchant_create_custody2`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustomers(params) {
    return basicAxiosInstance.get(`/customers/merchant_get_customers`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustomer(id) {
    return basicAxiosInstance.get(`/customers/merchant_get_customer/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editCustomer(body, id) {
    return basicAxiosInstance.post(`/customers/merchant_edit_customer/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addCustomer(body) {
    return basicAxiosInstance.post(`/customers/merchant_add_customer`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeCustomer(id) {
    return basicAxiosInstance.get(`/customers/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getPhoneCode() {
    return basicAxiosInstance.get(`/phone_code`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function changePassword(body) {
    return basicAxiosInstance.post(`/user/change_password`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function changeApprovalCode(body) {
    return basicAxiosInstance.post(`/user/change_approval_code`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

export async function getReports(params, props) {
    const queryString = objToQueryString(params);

    await fetch(`${url}/report?${queryString}`, {
        method: "GET",
        headers: new Headers({
            "Content-Type": "application/json",
            "Authorization": "Bearer " + props.auth.token
        })
    })
        .then(async responseJson => {
            console.log("getPDF resp", responseJson)

            if (responseJson.status === 200) {
                try {
                    const blob = await responseJson.blob();
                    const file = new Blob(
                        [blob],
                        { type: 'application/pdf' }
                    );
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                } catch (error) {
                    console.log("getPDF error", error)
                }

            }
        }).catch(error => {
            console.log(error);
        });

    // return basicAxiosInstance.get(`/report`, {
    //     params: params
    // }).then((response) => {
    //     console.log('getReports_response', response)
    //     return response;
    // }).catch((err) => {
    //     return err.response.data ?? err;
    // })
}

export async function getMerchantProfileDetail() {
    return basicAxiosInstance.get(`/merchant_profile`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updateMerchant(body) {
    return basicAxiosInstance.post('/update_merchant', body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getUsers(params) {
    return basicAxiosInstance.get(`/user/get_users`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getUser(id) {
    return basicAxiosInstance.get(`/user/get_user/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getUserRole() {
    return basicAxiosInstance.get(`/user/get_user_role`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editUser(body, id) {
    return basicAxiosInstance.post(`/user/edit_user/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addUser(body) {
    return basicAxiosInstance.post(`/user/add_user`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategories(params) {
    return basicAxiosInstance.get(`/product/get_merchant_categories`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategory(id) {
    return basicAxiosInstance.get(`/product/get_category/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editCategory(body, id) {
    return basicAxiosInstance.post(`/product/edit_category/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addCategory(body) {
    return basicAxiosInstance.post(`/product/add_category`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategories2(params) {
    return basicAxiosInstance.get(`/product/get_merchant_categories2`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCategory2(id) {
    return basicAxiosInstance.get(`/product/get_category2/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editCategory2(body, id) {
    return basicAxiosInstance.post(`/product/edit_category2/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addCategory2(body) {
    return basicAxiosInstance.post(`/product/add_category2`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getUOMs(params) {
    return basicAxiosInstance.get(`/product/get_merchant_uoms`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getUOM(id) {
    return basicAxiosInstance.get(`/product/get_uom/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editUOM(body, id) {
    return basicAxiosInstance.post(`/product/edit_uom/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addUOM(body) {
    return basicAxiosInstance.post(`/product/add_uom`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getMISC(id) {
    return basicAxiosInstance.get(`/misc`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editMISC(body, id) {
    return basicAxiosInstance.post(`/misc`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function extendCustody(body, id) {
    return basicAxiosInstance.post(`/custody/merchant_extend_custody`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function checkoutCustody(body, id) {
    return basicAxiosInstance.post(`/custody/merchant_checkout_custody`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCustody(params, id) {
    return basicAxiosInstance.get(`/custody/get_custody/${id}`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeProduct(id) {
    return basicAxiosInstance.get(`/product/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCustody(id) {
    return basicAxiosInstance.get(`/custody/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeEvent(id) {
    return basicAxiosInstance.get(`/events/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeVoucher(id) {
    return basicAxiosInstance.get(`/vouchers/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeUser(id) {
    return basicAxiosInstance.get(`/user/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeUOM(id) {
    return basicAxiosInstance.get(`/product/uom/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCategory(id) {
    return basicAxiosInstance.get(`/product/category_1/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeCategory2(id) {
    return basicAxiosInstance.get(`/product/category_2/remove/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getDashboard() {
    return basicAxiosInstance.get(`/dashboard`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getChart(params) {
    return basicAxiosInstance.get(`/get_chart`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updateProfile(body) {
    return basicAxiosInstance.post(`/update_profile`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getState(params) {
    return noTokenAxiosInstance.get(`/get_country_state`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getMerchantCategories() {
    return noTokenAxiosInstance.get(`/get_merchant_categories`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function registerMerchant(body) {
    return basicAxiosInstance.post(`/register`, body, {
        headers: { "Content-Type": "multipart/form-data;" },
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getPackages() {
    return basicAxiosInstance.get(`/get_packages`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createOrder(body) {
    return basicAxiosInstance.post(`/order/create_order`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function changePackage(body) {
    return basicAxiosInstance.post(`/order/change_package`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getSMSList(params) {
    return basicAxiosInstance.get(`/sms/get_smses`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getSMS(id) {
    return basicAxiosInstance.get(`/sms/get_sms/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editSMS(body) {
    return basicAxiosInstance.post(`/sms/edit_sms`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function sendSMS(body) {
    return basicAxiosInstance.post(`/sms/send_sms`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function merchantTopup(body) {
    return basicAxiosInstance.post(`/order/topup`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function merchantTopupSMSCredit(body) {
    return basicAxiosInstance.post(`/order/topup_sms_credit`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function merchantTopupSMSCreditByMerchantCredit(body) {
    return basicAxiosInstance.post(`/order/topup_sms_credit_by_merchant_credit`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getSMSCredit() {
    return basicAxiosInstance.get(`/sms/sms_credit`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getRedeemCustodyDetail(body) {
    return basicAxiosInstance.post(`/custody/merchant_redeem_custody_detail`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getRedeemCustody(body) {
    return basicAxiosInstance.post(`/custody/merchant_redeem_custody`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getRedeemVoucherDetail(body) {
    return basicAxiosInstance.post(`/vouchers/merchant_redeem_voucher_detail`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getRedeemVoucher(body) {
    return basicAxiosInstance.post(`/vouchers/merchant_redeem_voucher`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getLiveBandList(params) {
    return basicAxiosInstance.get(`/live_band/merchant_get_live_bands`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function addLiveBand(body) {
    return basicAxiosInstance.post(`/live_band/merchant_add_live_band`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editLiveBand(body, id) {
    return basicAxiosInstance.post(`/live_band/merchant_edit_live_band/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getLiveBand(id) {
    return basicAxiosInstance.get(`/live_band/merchant_get_live_band/${id}`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getSongList(params) {
    return basicAxiosInstance.get(`/live_band/merchant_get_live_band_songs`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}