import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getVoucherList, removeVoucher } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import { Accordion } from "react-bootstrap";
import Modal from 'react-modal';

const VoucherList = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setFilter] = useState({
        filter_name: "",
        filter_status: "",
        filter_sort_by: "",
        filter_order_by: "",
    });

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });
    };

    const submitFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        getList();
    }

    const sortOnchange = (voucher) => {
        const { name } = voucher.target;

        const tmp = filter;
        if (tmp.filter_order_by && tmp.filter_sort_by == name && tmp.filter_order_by == 'asc') {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = "desc";
        } else {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = 'asc'
        }

        setFilter({ ...tmp });
        setCurrentPage(1);
        getList();
    };

    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "member_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [currentPage])

    async function getList() {
        setLoadingData(true);
        let query = {
            id: merchant_id,
            name: filter.filter_name,
            status: filter.filter_status,
            page: currentPage,
            sort_by: filter.filter_sort_by,
            order_by: filter.filter_order_by,
        };

        let res = await getVoucherList(query);
        console.log('res', res);
        if (res?.status === 'success') {
            setData(res.data);
            // setFileData([...res.data])
        } else {
        }
        setLoadingData(false);
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeVoucher(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getList();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getList();
                }
            }
        })
    }

    const handlePageClick = (voucher) => {
        setCurrentPage(voucher.selected + 1);
    };

    const pageCount = Math.ceil(data.total_result / 20);

    const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);

    const [isModal5Open, setIsModal5Open] = useState(false);

    const [modalData, setModalData] = useState([]);

    const openModal5 = (id) => {
        console.log('openModal5', id);

        var tempdata = [];

        if (data && data.voucher_customers && data.voucher_customers.length > 0) {
            console.log('data.voucher_customers', data.voucher_customers)
            data.voucher_customers.map((item, index) => {
                if (item.voucher_id == id) {
                    tempdata.push(item);
                }
            });
        }

        console.log('tempdata', tempdata)
        setModalData(tempdata);
        setIsModal5Open(true);
    };

    const closeModal5 = () => {
        setModalData([]);
        setIsModal5Open(false);
    };

    const customStyles = {
        content: {
            transform: 'translate(0, 0%)',
        },
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Accordion
                        className="accordion accordion-with-icon"
                        defaultActiveKey="0"
                    >
                        <div className="accordion-item" key={1}>
                            <Accordion.Toggle
                                as={`div`}
                                voucherkey={`1`}
                                className={`accordion-header ${activeAccordionWithIcon === 1 ? "" : "collapsed"
                                    }`}
                                onClick={() =>
                                    setActiveAccordionWithIcon(
                                        activeAccordionWithIcon === 1 ? -1 : 1
                                    )
                                }
                            >
                                {/* <span className="accordion-header-icon"></span> */}
                                <span className="accordion-header-text ps-0"><i className="fas fa-filter"></i> {translate(lang, "btnFilter")}</span>
                                <span className="accordion-header-indicator indicator-bordered"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse voucherkey={`1`}>
                                <div className="accordion-body-text">
                                    <div className="header-filter">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="filter-name">{translate(lang, 'voucherName')}</label>
                                                    <input id="filter-name" type="text" name="filter_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'voucherName')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="filter-status">{translate(lang, 'status')}</label>
                                                    <select id="filter-status" name="filter_status" className="form-control" onChange={(e) => inputOnchange(e)}>
                                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                                        <option value="pending">{translate(lang, 'pending')}</option>
                                                        <option value="approved">{translate(lang, 'approved')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-filter-button text-end">
                                            <button className="btn btn-primary btn-md mt-3" onClick={(e) => submitFilter(e)}>{translate(lang, 'btnFilter')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div>

            <div className="card">
                {/* <div className="card-header">
                </div> */}
                <div className="card-body">
                    <div className="card-title">
                        <h3>{translate(lang, "voucherList")}</h3>
                        {/* <div className="header-button my-4">
                            <Link to="/voucher/create" className="btn btn-primary btn-sm">{translate(lang, 'addEvent')}</Link>
                        </div> */}
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            <div className="table-responsive TableVoucher">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'code') ? filter.filter_order_by : ""} filter_sort`} name="code" onClick={(e) => sortOnchange(e)}>{translate(lang, 'code')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'title') ? filter.filter_order_by : ""} filter_sort`} name="title" onClick={(e) => sortOnchange(e)}>{translate(lang, 'title')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'description') ? filter.filter_order_by : ""} filter_sort`} name="description" onClick={(e) => sortOnchange(e)}>{translate(lang, 'description')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'valid_from') ? filter.filter_order_by : ""} filter_sort`} name="valid_from" onClick={(e) => sortOnchange(e)}>{translate(lang, 'validFrom')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'valid_to') ? filter.filter_order_by : ""} filter_sort`} name="valid_to" onClick={(e) => sortOnchange(e)}>{translate(lang, 'validTo')}</a>
                                            </td>
                                            <td className="text-end">
                                                {translate(lang, 'quantity')}
                                            </td>
                                            <td>
                                                {translate(lang, 'status')}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(data && data.voucherResult && data.voucherResult.length > 0)
                                            ?
                                            data.voucherResult.map((value) => (
                                                <tr key={value.voucher_id}>
                                                    <td scope="row">{value.code}</td>
                                                    <td scope="row">{value.title}</td>
                                                    <td scope="row">{value.description}</td>
                                                    <td scope="row">{value.valid_from}</td>
                                                    <td scope="row">{value.valid_to}</td>
                                                    <td style={{}} >
                                                        <div onClick={(e) => { openModal5(value.voucher_id) }} className="btnQuantities">
                                                            <div>Total: {value.total_quantity}</div>
                                                            <div>Redeemed: {value.total_redeemed || 0}</div>
                                                            <div>Balance: {value.balance_quantity}</div>
                                                        </div>
                                                    </td>
                                                    <td scope="row">{value.status}</td>
                                                    <td scope="row">
                                                        {/* <a className="btn btn-xs btn-primary me-1" href={`/voucher/edit/${value.voucher_id}`}><i className="fas fa-pencil-alt"></i></a>
                                                        <a className="btn btn-xs me-1" href="#" title={translate(lang, 'remove')} onClick={() => removeItem(value.voucher_id)}><i className="fas fa-trash-alt"></i></a> */}
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={8} className="text-center">{translate(lang, 'noResult')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {(data && data.voucherResult && data.voucherResult.length > 0)
                                ?
                                <ReactPaginate
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={currentPage - 1}
                                />
                                : <></>}
                        </>
                    }
                </div>
            </div>

            <Modal
                isOpen={isModal5Open}
                onRequestClose={closeModal5}
                contentLabel="Modal 5"
                style={customStyles}
                className={"modal-dialog"}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" style={{ border: "0", backgroundColor: "transparent", fontSize: "16px", padding: "16px 22px 12px 12px" }} onClick={closeModal5}><i className="fas fa-times"></i></button>
                        <div className="m-0" style={{ fontSize: 16, color: '#ffffff' }}>{translate(lang, "voucherRedeemList")}</div>
                    </div>
                    <div className="modal-body" style={{ maxHeight: "500px", overflow: "auto" }}>
                        <div className="row">
                            {(modalData && modalData.length > 0)
                                ?
                                modalData.map((item) => (
                                    <div style={{ color: '#dddddd', padding: '8px 0 8px 0', borderBottom: '1px solid #888888' }}>
                                        <div>{item.redeemed_date}</div>
                                        <div>{item.name}</div>
                                        <div>{item.phonecode}{item.phonenumber}</div>
                                    </div>
                                ))
                                :
                                <></>
                            }
                        </div>
                        {/* <div className="mb-3">
                            <span>{translate(lang, "sendTo")} {customerData.customer_phone}</span>
                        </div>
                        <hr />
                        <div className="mb-3">
                            {TextWithLineBreaks(customerData.sms_body)}
                        </div> */}
                    </div>
                    <div className="modal-footer">
                        {/* <button className="btn btn-success" onClick={submitForm4}>{translate(lang, 'sendNow')}</button> */}
                        <button className="btn btn-secondary" onClick={closeModal5}>{translate(lang, 'close')}</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(VoucherList);