import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getCategory2, addCategory2, editCategory2 } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";

const CategoryForm2 = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [userRoleData, setUserRoleData] = useState([]);
    const [data, setData] = useState({
        name: "",
    });

    const [error, setError] = useState({
        name: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: "",
            })
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/settings/category_2');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        // let params = { id: slug };
        let res = await getCategory2(slug);

        setData({
            name: "",
        });

        if (res.status === 'success') {
            let data = res.data;

            setData({
                name: (data.name) ? data.name : "",
            });

        } else {
            history.push('/settings/category_2');
        }
    }

    const submitForm = async () => {
        let check = true;
        let error = {
            name: "",
        }

        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }

        setError({ ...error });
        if (check) {
            const formData = {
                "name": data.name,
                "merchant_id": merchant_id
            }

            if (type == 'create') {
                await addCategory2(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/category_2');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                await editCategory2(formData, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/category_2');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "edit" ? "editCategory" : "createCategory"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group mb-3 required">
                                <label htmlFor="input-name" className="form-label">{translate(lang, 'name')}</label>
                                <input id="input-name" type="text" className="form-control" name="name" value={data.name} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                {(error.name)
                                    ?
                                    <label className="text-danger">{error.name}</label>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/category_2')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CategoryForm2);