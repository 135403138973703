import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getSMSList, getSMSCredit } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import { Accordion } from "react-bootstrap";

const SMSList = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setFilter] = useState({
        filter_name: "",
        filter_status: "",
        filter_sort_by: "",
        filter_order_by: "",
    });

    const inputOnchange = (e) => {
        const tmp = filter;
        tmp[e.target.name] = e.target.value;
        setFilter({ ...tmp });
    };

    const submitFilter = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        getList();
    }

    useEffect(() => {
        getSMSCredit1()
    }, [])

    const [creditData, setCreditData] = useState(0);

    async function getSMSCredit1() {
        let res = await getSMSCredit();

        if (res?.status === 'success') {
            setCreditData(res.data);
            // setFileData([...res.data])
        } else {
        }
    }

    const sortOnchange = (event) => {
        const { name } = event.target;

        const tmp = filter;
        if (tmp.filter_order_by && tmp.filter_sort_by == name && tmp.filter_order_by == 'asc') {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = "desc";
        } else {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = 'asc'
        }

        setFilter({ ...tmp });
        setCurrentPage(1);
        getList();
    };

    // const tableColumns = [
    //     {
    //         Header: translate(lang, 'index'),
    //         accessor: (row, index) => {
    //             return index + 1;
    //         }
    //     },
    //     {
    //         Header: translate(lang, 'eventImage'),
    //         accessor: (row, index) => {
    //             // return row.event_image;
    //         }
    //     },
    //     {
    //         Header: translate(lang, 'eventName'),
    //         accessor: (row, index) => {
    //             return row.name;
    //         }
    //     },
    //     {
    //         Header: translate(lang, 'eventStart'),
    //         accessor: (row, index) => {
    //             // return row.event_start;
    //             return moment.utc(row.event_start).utcOffset(8).format(translate(lang, 'date_time_format'));
    //         }
    //     },
    //     {
    //         Header: translate(lang, 'eventEnd'),
    //         accessor: (row, index) => {
    //             return moment.utc(row.event_end).utcOffset(8).format(translate(lang, 'date_time_format'));
    //         }
    //     },
    //     {
    //         Header: translate(lang, 'action'),
    //         accessor: (row, index) => {
    //             return (<>
    //                 <Link
    //                     to={`/agent/view/${row.id}`}
    //                     className="btn btn-primary shadow btn-xs sharp me-1"
    //                 >
    //                     <i className="fas fa-eye"></i>
    //                 </Link>
    //                 <Link
    //                     to={`/agent/edit/${row.id}`}
    //                     className="btn btn-warning shadow btn-xs sharp me-1"
    //                 >
    //                     <i className="fas fa-pen"></i>
    //                 </Link>
    //                 <span
    //                     onClick={() => removeItem(row.id)}
    //                     className="btn btn-danger shadow btn-xs sharp"
    //                 >
    //                     <i className="fa fa-trash"></i>
    //                 </span>
    //             </>)
    //         }
    //     },
    // ]
    const [data, setData] = useState([]);
    // const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "member_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [currentPage])

    async function getList() {
        setLoadingData(true);
        let query = {
            page: currentPage,
            sort_by: filter.filter_sort_by,
            order_by: filter.filter_order_by,
        };

        let res = await getSMSList(query);

        if (res?.status === 'success') {
            setData(res.data);
            // setFileData([...res.data])
        } else {
        }
        setLoadingData(false);
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                // let res = await removeEvent(id)
                // if (res.status === 'success') {
                //     promptMessage(lang, 'success', res.message)
                //     getList();
                // } else {
                //     promptMessage(lang, 'error', res.message)
                //     getList();
                // }
            }
        })
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const pageCount = Math.ceil(data.total_results / 20);

    const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);

    return (
        <>
            {/* <div className="card">
                <div className="card-body">
                    <Accordion
                        className="accordion accordion-with-icon"
                        defaultActiveKey="0"
                    >
                        <div className="accordion-item" key={1}>
                            <Accordion.Toggle
                                as={`div`}
                                eventKey={`1`}
                                className={`accordion-header ${activeAccordionWithIcon === 1 ? "" : "collapsed"
                                    }`}
                                onClick={() =>
                                    setActiveAccordionWithIcon(
                                        activeAccordionWithIcon === 1 ? -1 : 1
                                    )
                                }
                            >
                                <span className="accordion-header-text ps-0"><i className="fas fa-filter"></i> {translate(lang, "btnFilter")}</span>
                                <span className="accordion-header-indicator indicator-bordered"></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`1`}>
                                <div className="accordion-body-text">
                                    <div className="header-filter">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="filter-name">{translate(lang, 'eventName')}</label>
                                                    <input id="filter-name" type="text" name="filter_name" className="form-control" onChange={(e) => inputOnchange(e)} placeholder={translate(lang, 'eventName')}></input>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="filter-status">{translate(lang, 'status')}</label>
                                                    <select id="filter-status" name="filter_status" className="form-control" onChange={(e) => inputOnchange(e)}>
                                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                                        <option value="pending">{translate(lang, 'pending')}</option>
                                                        <option value="approved">{translate(lang, 'approved')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-filter-button text-end">
                                            <button className="btn btn-primary btn-md mt-3" onClick={(e) => submitFilter(e)}>{translate(lang, 'btnFilter')}</button>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </div>
            </div> */}

            <div className="card">
                <div className="card-body">
                    <div className="card-title">
                        <p className="" style={{color: "#828690"}}>{creditData} {translate(lang, "creditLeft")}</p>
                        <h3>{translate(lang, "SMSList")}</h3>
                    </div>
                    {(loadingData) ?
                        <>
                            <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                        </>
                        :
                        <>
                            <div className="table-responsive">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'type') ? filter.filter_order_by : ""} filter_sort`} name="type" onClick={(e) => sortOnchange(e)}>{translate(lang, 'type')}</a>
                                            </td>
                                            <td className="position-relative">
                                                <a href="#" className={`${(filter.filter_sort_by == 'text') ? filter.filter_order_by : ""} filter_sort`} name="text" onClick={(e) => sortOnchange(e)}>{translate(lang, 'text')}</a>
                                            </td>
                                            <td>
                                                {translate(lang, 'action')}
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(data && data.results && data.results.length > 0)
                                            ?
                                            data.results.map((value) => (
                                                <tr key={value.id}>
                                                    <td scope="row">{value.type}</td>
                                                    <td scope="row">{value.text}</td>
                                                    <td scope="row">
                                                        <a className="btn btn-xs btn-primary me-1" href={`/settings/sms/edit/${value.id}`}><i className="fas fa-pencil-alt"></i></a>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={3} className="text-center">{translate(lang, 'noResult')}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {(data && data.results && data.results.length > 0)
                                ?
                                <ReactPaginate
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                    previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                    nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    forcePage={currentPage - 1}
                                />
                                : <></>}
                        </>
                    }
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(SMSList);