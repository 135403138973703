import Swal from "sweetalert2";
import { translate } from "./translate";

export const promptMessage = (lang, type, msg, optional = {}) => {
    let basic = {
        showConfirmButton: false,
        timer: 2500,
    }
    basic = {...basic, ...optional}
    Swal.fire({
        icon: type,
        title: translate(lang, type),
        text: lang === "EN" ? msg.toUpperCase() : msg,
        ...basic,
    });
}

export const dataDisplayOptions = (lang) => {
    let options = [
        {id: 1, label: translate(lang, 'ring'), value: "ring", isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'barChart'), value: "barChart", isSelected: false, isHidden: false,},
    ]
    return options.filter(i => i.isHidden === false);
}

export const genderOptions = (lang) => {
    let options = [
        {id: 1, label: translate(lang, 'male'), value: "male", isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'female'), value: "female", isSelected: false, isHidden: false,},
    ]
    return options.filter(i => i.isHidden === false);
}

export const relationshipOptions = (lang) => {
    let options = [
        {id: 1, label: translate(lang, 'father'), value: "father", isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'mother'), value: "mother", isSelected: false, isHidden: false,},
        {id: 3, label: translate(lang, 'guardian'), value: "guardian", isSelected: false, isHidden: false,},
        {id: 4, label: translate(lang, 'child'), value: "child", isSelected: false, isHidden: false,},
    ]
    return options.filter(i => i.isHidden === false);
}

export const questionairType = (lang) => {
    let types = [
        {id: 1, label: translate(lang, 'chooseOne'), value: 'chooseOne', isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'chooseMultiple'), value: 'chooseMulti', isSelected: false, isHidden: false,},
        {id: 3, label: translate(lang, 'fillIn'), value: 'fillIn', isSelected: false, isHidden: false,},
    ]
    return types.filter(t => t.isHidden === false);
}

export const languageOptions = (lang) => {
    let langs = [
        {id: 1, label: translate(lang, 'chooseOne'), value: 'en', isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'chooseOne'), value: 'cn', isSelected: false, isHidden: false,},
        {id: 3, label: translate(lang, 'chooseOne'), value: 'bm', isSelected: false, isHidden: false,},
    ]
    return langs.filter(l => l.isHidden === false);
}

export const referenceOptions = (lang) => {
    let langs = [
        {id: 1, label: translate(lang, 'url'), value: 'url', isSelected: false, isHidden: false,},
        {id: 2, label: translate(lang, 'image'), value: 'image', isSelected: false, isHidden: true,},
    ]
    return langs.filter(l => l.isHidden === false);
}