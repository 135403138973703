import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getProduct, getCategoryList, getCategoryList2, editProduct, getProductTemplate, createProduct, getProductUOMList } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";

const CreateProduct = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    const history = useHistory();
    const [file, setFile] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryData2, setCategoryData2] = useState([]);
    const [productUOMData, setProductUOMData] = useState([]);
    const [data, setData] = useState({
        productID: "",
        productImage: "",
        productImageDefaultValue: "",
        productCategory: "",
        productCategory2: "",
        productCode: "",
        productName: "",
        productUOM: "",
        productUOMDesc: "",
        productDefaultExpiryRange: "days",
        productDefaultExpiryUnit: "",
    });
    const [error, setError] = useState({
        productID: "",
        productImage: "",
        productImageDefaultValue: "",
        productCategory: "",
        productCategory2: "",
        productCode: "",
        productName: "",
        productUOM: "",
        productUOMDesc: "",
        productDefaultExpiryRange: "",
        productDefaultExpiryUnit: "",
    });

    useEffect(() => {
        getCategories();
        getCategories2();
        getProductUOM();

        if (type === 'create') {

            if (!slug) {
                setData({
                    productID: "",
                    productImage: "",
                    productImageDefaultValue: "",
                    productCategory: "",
                    productCategory2: "",
                    productCode: "",
                    productName: "",
                    productUOM: "",
                    productUOMDesc: "",
                    productDefaultExpiryRange: "days",
                    productDefaultExpiryUnit: "",
                });
            } else {
                getProductTemplateDetail(slug);
            }

        } else if (type === 'view') {
            if (!slug) {
                history.push('/product');
                return;
            }
            // getDetails(slug);
            // getMemberList(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/product');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        let res = await getProduct(slug);

        if (res.status === 'success') {
            let data = res.data;

            setData({
                productID: data.id,
                productImage: data.default_prod_image,
                productImageDefaultValue: "",
                productCategory: data.category_1,
                productCategory2: data.category_2,
                productCode: data.code,
                productName: data.description,
                productUOM: data.uom,
                productUOMDesc: data.uom_desc,
                productDefaultExpiryRange: data.default_expiry_range,
                productDefaultExpiryUnit: data.default_expiry_unit,
            });
        } else {
            history.push('/product');
        }
    }

    const getProductTemplateDetail = async (slug) => {
        let res = await getProductTemplate(slug);

        setData({
            productID: "",
            productImage: "",
            productImageDefaultValue: "",
            productCategory: "",
            productCategory2: "",
            productCode: "",
            productName: "",
            productUOM: "",
            productUOMDesc: "",
            productDefaultExpiryRange: "",
            productDefaultExpiryUnit: "",
        });

        if (res.status === 'success') {
            let data = res.data;

            // check if product uom exist in data uom
            let uomFlag = false;
            // let productUOMData1 = [];

            // let query = {
            //     merchant_id: merchant_id,
            // };

            // const res = await getProductUOMList(query);

            // if (res.status === 'success') {
            //     productUOMData1 = res.data;
            // }

            // productUOMData.find(item => {
            //     console.log(Object.values(item));
            //     if (Object.values(item).includes(data.uom)) {
            //         uomFlag = true;
            //     }
            // });

            // // console.log(uomFlag, "uom Flag");
            // // console.log(data.uom, "uom Flag");
            // console.log(productUOMData, "uom Flag");

            setData({
                productID: "",
                productImage: data.image_path,
                productImageDefaultValue: data.image_path_value,
                productCategory: data.category_1,
                productCategory2: data.category_2,
                productCode: "",
                productName: data.name,
                productUOM: (uomFlag) ? data.uom : "",
                productUOMDesc: (uomFlag) ? data.uom_desc : "",
                productDefaultExpiryRange: "",
                productDefaultExpiryUnit: "",
            });
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const onOptionChange = (event) => {
        const selectedName = event.target[event.target.selectedIndex].text;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        temp["productUOMDesc"] = selectedName;
        setData({ ...temp });
    }

    const onFileChange = (event) => {
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        let error = {
            productID: "",
            productImage: "",
            productImageDefaultValue: "",
            productCategory: "",
            productCategory2: "",
            productCode: "",
            productName: "",
            productUOM: "",
            productUOMDesc: "",
            productDefaultExpiryRange: "",
            productDefaultExpiryUnit: "",
        }

        if (!data.productName) {
            check = false;
            error.productName = translate(lang, "errorRequired");
        }
        if (!data.productCode) {
            check = false;
            error.productCode = translate(lang, "errorRequired");
        }
        if (!data.productCategory) {
            check = false;
            error.productCategory = translate(lang, "errorRequired");
        }
        if (!data.productUOM || !data.productUOMDesc) {
            check = false;
            error.productUOM = translate(lang, "errorRequired");
        }

        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("name", data.productName);
            formData.append("merchant_id", merchant_id);
            formData.append("code", data.productCode);
            formData.append("category", data.productCategory);
            formData.append("category2", data.productCategory2);
            formData.append("uom", data.productUOM);
            formData.append("uom_desc", data.productUOMDesc);
            formData.append("expiry_unit", data.productDefaultExpiryUnit);
            formData.append("expiry_range", data.productDefaultExpiryRange);

            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("image", file[i]);
                }
            } else {
                if (type == 'create') {
                    formData.append("default_image", data.productImageDefaultValue);
                }
            }

            if (type == 'create') {
                await createProduct(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/product');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                await editProduct(formData, data.productID).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/product');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    async function getCategories() {
        let query = {
            merchant_id: merchant_id,
        };

        let res = await getCategoryList(query);

        if (res?.status === 'success') {
            setCategoryData(res.data)
        } else {
        }
    }

    async function getCategories2() {
        let query = {
            merchant_id: merchant_id,
        };

        let res = await getCategoryList2(query);

        if (res?.status === 'success') {
            setCategoryData2(res.data)
        } else {
        }
    }

    async function getProductUOM() {
        let query = {
            merchant_id: merchant_id,
        };

        let res = await getProductUOMList(query);


        if (res?.status === 'success') {
            let uom_data = res.data;

            setProductUOMData(res.data);

            if (type == 'create' && slug) {
                let res2 = await getProductTemplate(slug);

                setData({
                    productID: "",
                    productImage: "",
                    productImageDefaultValue: "",
                    productCategory: "",
                    productCategory2: "",
                    productCode: "",
                    productName: "",
                    productUOM: "",
                    productUOMDesc: "",
                    productDefaultExpiryRange: "",
                    productDefaultExpiryUnit: "",
                });

                if (res2.status === 'success') {
                    let data = res2.data;

                    // check if product uom exist in data uom
                    let uomFlag = false;

                    uom_data.find(item => {
                        if (Object.values(item).includes(data.uom)) {
                            uomFlag = true;
                        }
                    });

                    setData({
                        productID: "",
                        productImage: data.image_path,
                        productImageDefaultValue: data.image_path_value,
                        productCategory: data.category_1,
                        productCategory2: data.category_2,
                        productCode: "",
                        productName: data.name,
                        productUOM: (uomFlag) ? data.uom : "",
                        productUOMDesc: (uomFlag) ? data.uom_desc : "",
                        productDefaultExpiryRange: "",
                        productDefaultExpiryUnit: "",
                    });
                }
            }
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "edit" ? "editProduct" : "createProduct"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group py-2">
                                <div>
                                    <label className="form-label">{translate(lang, 'productImage')}</label>
                                    <input type="file" name="productImage" className="form-control h-100" accept="image/*" onChange={(e) => onFileChange(e)} />
                                    <div className="">
                                        {(file.length == 0 && data.productImage)
                                            ?
                                            <img src={(data.productImage) ? data.productImage : logo512} width={200} height={200} className="w-image-fluid mt-3" />
                                            : <></>
                                        }
                                        {/* <button className="btn btn-danger btn-xs ms-3">{translate(lang, 'Delete')}</button> */}
                                    </div>
                                    <label className="error">{error.eventImage}</label>
                                </div>
                            </div>
                            <div className="form-group py-2 required">
                                <label className="form-label">{translate(lang, 'productName')}</label>
                                <input type="text" className="form-control" name="productName" value={data.productName} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                <label className="text-danger">{error.productName}</label>
                            </div>
                            <div className="form-group py-2 required">
                                <label className="form-label">{translate(lang, 'productCode')}</label>
                                <input type="text" className="form-control" name="productCode" value={data.productCode} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                <label className="text-danger">{error.productCode}</label>
                            </div>
                            <div className="form-group row py-2 required">
                                <div className="col-sm-4">
                                    <label className="form-label" htmlFor="filter-category">{translate(lang, 'category')}</label>
                                    <select className="form-control" name="productCategory" onChange={(e) => onInputChange(e)} value={data.productCategory}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(categoryData && categoryData.length > 0)
                                            ?
                                            categoryData.map((value) => (
                                                <option key={value.id} value={value.name} >{value.name}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select>
                                    <label className="text-danger">{error.productCategory}</label>
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="filter-category-2">{translate(lang, 'category2')}</label>
                                    <select className="form-control" name="productCategory2" onChange={(e) => onInputChange(e)} value={data.productCategory2}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(categoryData2 && categoryData2.length > 0)
                                            ?
                                            categoryData2.map((value) => (
                                                <option key={value.id} value={value.name} >{value.name}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <label className="form-label">{translate(lang, 'productUOM')}</label>
                                    <select className="form-control" name="productUOM" onChange={(e) => onOptionChange(e)} value={data.productUOM}>
                                        <option value="">{translate(lang, 'pleaseSelect')}</option>
                                        {(productUOMData && productUOMData.length > 0)
                                            ?
                                            productUOMData.map((value) => (
                                                <option key={value.id} value={value.uom} >{value.description}</option>
                                            ))
                                            :
                                            <></>
                                        }
                                    </select>
                                    <label className="text-danger">{error.productUOM}</label>
                                </div>
                            </div>
                            <div className="form-group py-2">
                                <label className="form-label">{translate(lang, 'productDefaultExpiryPeriod')}</label>
                                <div className="col-sm-12">
                                    <label className="radio-inline me-3">
                                        <input type="radio" defaultChecked name="expire_type" value="" /> {translate(lang, 'expiredAfter')}
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="number" className="form-control" name="productDefaultExpiryUnit" value={data.productDefaultExpiryUnit} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        <small>{translate(lang, 'productDefaultExpiryUnitHelp')}</small>
                                        <label className="text-danger">{error.productDefaultExpiryUnit}</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <select className="form-control" name="productDefaultExpiryRange" onChange={(e) => onInputChange(e)} value={data.productDefaultExpiryRange}>
                                            <option value="days">{translate(lang, 'days')}</option>
                                            <option value="weeks">{translate(lang, 'weeks')}</option>
                                            <option value="months">{translate(lang, 'months')}</option>
                                            <option value="years">{translate(lang, 'years')}</option>
                                        </select>
                                        <label className="text-danger">{error.productDefaultExpiryRange}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/product')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(CreateProduct);