import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { getMISC, editMISC } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Switch from 'react-switch';

const UOMForm = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [data, setData] = useState({
        name: "",
    });

    const [isChecked, setIsChecked] = useState({
        "custody_out_quantity": false,
        "approval_code": false,
        "premium_sms": false,
        "enable_qr": false,
        "default_sms": false,
        "lock_expiry_period": false
    });

    const handleSwitchChange = (checked, event, id) => {
        updateMISC(id, checked);
        const tmp = isChecked;
        tmp[id] = checked;
        setIsChecked({ ...tmp });
    };

    useEffect(() => {
        getDetails();
    }, [])

    const getDetails = async (slug) => {
        let res = await getMISC();

        if (res.status === 'success') {
            let data = res.data;

            setIsChecked({
                "custody_out_quantity": (data.custody_out_quantity) ? data.custody_out_quantity : false,
                "approval_code": (data.approval_code) ? data.approval_code : false,
                "premium_sms": (data.premium_sms) ? data.premium_sms : false,
                "enable_qr": (data.enable_qr) ? data.enable_qr : false,
                "default_sms": (data.default_sms) ? data.default_sms : false,
                "lock_expiry_period": (data.lock_expiry_period) ? data.lock_expiry_period : false,
            })

        } else {
            history.push('/settings/misc');
        }
    }

    const updateMISC = async (type, checked) => {
        const formData = {
            type,
            flag: checked
        }
        await editMISC(formData).then((res) => {
            if (res.status === 'success') {
                promptMessage(lang, 'success', res.message);
                getDetails();
            } else {
                promptMessage(lang, 'error', res.message)
            }
        }).catch((error) => {
            promptMessage(lang, 'error', error);
        })

        console.log(checked, "checked");
    }


    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-12">
                    <div className="card">
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Custody Out Quantity</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> if you need partial or full Custody Out Quantity.</p>
                                        <p className="mb-0">Turn <span className="text-danger">Off</span> if you only need full Custody Out Quantity.</p>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.custody_out_quantity}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="custody_out_quantity"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Approval Code</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> if you need custody approval.</p>
                                        <p className="mb-0">Turn <span className="text-danger">Off</span> if you do not need custody approval.</p>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.approval_code}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="approval_code"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Premium SMS</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> to use online SMS service.</p>
                                        <p className="mb-0">Turn <span className="text-danger">Off</span> to use mobile built-in SMS app.</p>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.premium_sms}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="premium_sms"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Default SMS</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> to always enable SMS.</p>
                                        <p className="mb-0">Turn <span className="text-danger">Off</span> for user to decide sending SMS.</p>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.default_sms}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="default_sms"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Enable QR</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> if you need partial or full Custody Out Quantity.</p>
                                        <ul className="ms-3 ps-3" >
                                            <li style={{ listStyle: "disc" }}>Allow customer check out via QR code</li>
                                            <li style={{ listStyle: "disc" }}>Allow merchant provide QR code for customer registration</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.enable_qr}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="enable_qr"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group mb-3 pb-3 misc-form-group">
                                <div className="d-flex justify-content-space-between">
                                    <div>
                                        <h5>Enable Lock Expiry Period</h5>
                                        <p className="mb-0">Turn <span className="text-success">On</span> if you want to lock the default expiry period.</p>
                                        <ul className="ms-3 ps-3" >
                                            {/* <li style={{ listStyle: "disc" }}>Allow customer check out via QR code</li> */}
                                            {/* <li style={{ listStyle: "disc" }}>Allow merchant provide QR code for customer registration</li> */}
                                        </ul>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={isChecked.lock_expiry_period}
                                            onChange={handleSwitchChange}
                                            onColor="#86d3ff"
                                            onHandleColor="#2693e6"
                                            handleDiameter={30}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={20}
                                            width={48}
                                            className="react-switch"
                                            id="lock_expiry_period"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(UOMForm);