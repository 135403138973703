import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { translate } from "../../../utils/translate";

const OrderStatus = (props) => {
    const query = useLocation().search;
    const [message, setMessage] = useState("");

    useEffect(async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const paidstatus = queryParams.get('paid');

        console.log('paidstatus', paidstatus)


        if (paidstatus == 'true') {
            setMessage("Payment success");
        } else {
            setMessage("Payment failed")
        }
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h1>{translate(props.lang, "paymentStatus")}</h1>
                    <h5>{translate(props.lang, message)}</h5>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(OrderStatus);