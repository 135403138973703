import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getProfileDetail, changeApprovalCode, changePassword, updateProfile } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';
import logo512 from "../../../images/logo512.png";

const Profile = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const user_id = props.auth.auth.user_id;

    const [postData, setPostData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        currentCode: "",
        newCode: "",
        confirmCode: "",
        phone: "",
        email: ""
    });

    const [data, setData] = useState({});

    const [error, setError] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        currentCode: "",
        newCode: "",
        confirmCode: "",
        phone: "",
    });

    useEffect(() => {
        getProfile();
    }, [])

    async function getProfile() {
        let res = await getProfileDetail();

        if (res?.status === 'success') {
            setData(res.data);

            setPostData(
                {
                    phone: (res.data.phone) ? res.data.phone : "",
                    email: (res.data.email) ? res.data.email : ""
                }
            );
        } else {

        }
    }

    const onInputChange = (event) => {
        const { value, name } = event.target;
        let temp = postData;
        temp[name] = value;
        setPostData({ ...temp });
    };

    const submitChangePasswordForm = async () => {
        let check = true;
        let error = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentCode: "",
            newCode: "",
            confirmCode: "",
            phone: "",
        }

        if (!postData.currentPassword) {
            check = false;
            error.currentPassword = translate(lang, "errorRequired");
        }

        if (!postData.newPassword) {
            check = false;
            error.newPassword = translate(lang, "errorRequired");
        }

        if (!postData.confirmPassword) {
            check = false;
            error.confirmPassword = translate(lang, "errorRequired");
        } else {
            if (postData.newPassword != postData.confirmPassword) {
                check = false;
                error.confirmPassword = translate(lang, "errorPasswordNotMatch");
            }
        }

        setError({ ...error });

        if (check) {

            const tmp_data = {
                "current_password": postData.currentPassword,
                "new_password": postData.newPassword,
            }

            await changePassword(tmp_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    resetForm();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }
    }

    const submitChangeApprovalCodeForm = async (e) => {
        let check = true;
        let error = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentCode: "",
            newCode: "",
            confirmCode: "",
            phone: "",
        }

        if (!postData.newCode) {
            check = false;
            error.newCode = translate(lang, "errorRequired");
        }

        if (!postData.confirmCode) {
            check = false;
            error.confirmCode = translate(lang, "errorRequired");
        } else {
            if (postData.newCode != postData.confirmCode) {
                check = false;
                error.confirmCode = translate(lang, "errorPasswordNotMatch");
            }
        }

        setError({ ...error });

        if (check) {

            const tmp_data = {
                "current_code": postData.currentCode,
                "new_code": postData.newCode,
            }

            await changeApprovalCode(tmp_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    resetForm();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }
    }

    const submitUpdateProfile = async () => {
        let check = true;

        let error = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentCode: "",
            newCode: "",
            confirmCode: "",
            phone: "",
        }

        if (!postData.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }

        setError({ ...error });

        if (check) {
            const tmp_data = {
                "phone": postData.phone,
                "email": postData.email,
            }

            await updateProfile(tmp_data).then((res) => {
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    resetForm();
                } else {
                    promptMessage(lang, 'error', res.message)
                }
            }).catch((error) => {
                promptMessage(lang, 'error', error);
            })
        }
    }

    const resetForm = () => {
        setPostData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            currentCode: "",
            newCode: "",
            confirmCode: "",
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, "profile")}
                    </h3>
                </div>
                <div className="card-body">
                    <div className="profile-row">

                        <div className="form-group row mb-3">
                            <label className="col-sm-2 form-label" htmlFor="input-id">{translate(lang, 'loginID')}:</label>
                            <div className="col-sm-10">
                                <p>{data.login_id}</p>
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label className="col-sm-2 form-label" htmlFor="input-email">{translate(lang, 'name')}:</label>
                            <div className="col-sm-10">
                                <p>{data.display_name}</p>
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label className="col-sm-2 form-label" htmlFor="input-email">{translate(lang, 'userType')}:</label>
                            <div className="col-sm-10">
                                <span className="label label-primary">{data.user_type}</span>
                            </div>
                        </div>

                        <div className="form-group row mb-3 required">
                            <label className="col-sm-2 form-label" htmlFor="input-phone">{translate(lang, 'phone')}:</label>
                            <div className="col-sm-10">
                                <input id="input-phone" type="text" name="phone" className="form-control" placeholder={translate(lang, 'phone')} onChange={(e) => onInputChange(e)} value={postData.phone}></input>

                                {(error.phone)
                                    ?
                                    <label className="text-danger">{error.phone}</label>
                                    :
                                    <></>
                                }
                            </div>
                        </div>

                        <div className="form-group row mb-3">
                            <label className="col-sm-2 form-label" htmlFor="input-email">{translate(lang, 'email')}:</label>
                            <div className="col-sm-10">
                                <input id="input-email" type="text" name="email" className="form-control" placeholder={translate(lang, 'email')} onChange={(e) => onInputChange(e)} value={postData.email}></input>
                            </div>
                        </div>

                        <div className="d-flex flex-row justify-content-end">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => submitUpdateProfile()}>{translate(lang, 'update')}</button>
                        </div>
                    </div>

                    <div className="password-row row mt-5">
                        <div className="col-sm-6">
                            <h5>{translate(lang, 'changePassword')}</h5>
                            <p><small>{translate(lang, 'changePasswordHelp')}</small></p>

                            <div className="form-group mb-3">
                                {/* <label className="col-form-label" htmlFor="input-current-password">{translate(lang, 'currentPassword')}</label> */}
                                <input id="input-current-password" type="password" name="currentPassword" className="form-control" value={postData.currentPassword} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'currentPassword')}></input>
                                {(error.currentPassword)
                                    ?
                                    <label className="text-danger">{error.currentPassword}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3">
                                {/* <label className="col-form-label" htmlFor="input-new-password">{translate(lang, 'newPassword')}</label> */}
                                <input id="input-new-password" type="password" name="newPassword" className="form-control" value={postData.newPassword} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'newPassword')}></input>
                                {(error.newPassword)
                                    ?
                                    <label className="text-danger">{error.newPassword}</label>
                                    : <></>
                                }
                            </div>

                            <div className="form-group mb-3">
                                {/* <label className="col-form-label" htmlFor="input-confirm-password">{translate(lang, 'confirmPassword')}</label> */}
                                <input id="input-confirm-password" type="password" name="confirmPassword" className="form-control" value={postData.confirmPassword} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'confirmPassword')}></input>
                                {(error.confirmPassword)
                                    ?
                                    <label className="text-danger">{error.confirmPassword}</label>
                                    : <></>
                                }
                            </div>

                            <div className="password-row-footer">
                                <div className="d-flex flex-row justify-content-end">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => submitChangePasswordForm()}>{translate(lang, 'changePassword')}</button>
                                </div>
                            </div>

                        </div>
                        {(data.user_type == 'bartender')
                            ?
                            <div className="col-sm-6">
                                <h5>{translate(lang, 'changeApprovalCode')}</h5>
                                <p><small>{translate(lang, 'changeApprovalCodeHelp')}</small></p>

                                <div className="form-group mb-3">
                                    {/* <label className="col-form-label" htmlFor="input-current-code">{translate(lang, 'currentCode')}</label> */}
                                    <input id="input-current-code" type="password" name="currentCode" className="form-control" value={postData.currentCode} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'currentCode')}></input>
                                </div>

                                <div className="form-group mb-3">
                                    {/* <label className="col-form-label" htmlFor="input-new-code">{translate(lang, 'newCode')}</label> */}
                                    <input id="input-new-code" type="password" name="newCode" className="form-control" value={postData.newCode} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'newCode')}></input>
                                    {(error.newCode)
                                        ?
                                        <label className="text-danger">{error.newCode}</label>
                                        : <></>
                                    }
                                </div>

                                <div className="form-group mb-3">
                                    {/* <label className="col-form-label" htmlFor="input-confirm-code">{translate(lang, 'confirmCode')}</label> */}
                                    <input id="input-confirm-code" type="password" name="confirmCode" className="form-control" value={postData.confirmCode} onChange={(e) => onInputChange(e)} placeholder={translate(lang, 'confirmCode')}></input>
                                    {(error.newCode)
                                        ?
                                        <label className="text-danger">{error.confirmCode}</label>
                                        : <></>
                                    }
                                </div>

                                <div className="password-row-footer">
                                    <div className="d-flex flex-row justify-content-end">
                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => submitChangeApprovalCodeForm()}>{translate(lang, 'changeApprovalCode')}</button>
                                    </div>
                                </div>
                            </div>
                            : <></>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(Profile);