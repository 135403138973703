import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { addLiveBand, editLiveBand, getLiveBand, getCustomerOnlyAutoComplete, getSongList } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
// import TimePicker from 'react-weblineindia-time-picker'
import logo512 from "../../../images/logo512.png";
import Autocomplete from 'react-autocomplete';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import { Table } from "reactstrap";
import ReactPaginate from 'react-paginate';

const CreateLiveBand = (props) => {
    const lang = props.lang;
    const merchant_id = props.auth.auth.merchant_id;
    const { type, slug } = useParams();
    // const [memberList, setMemberList] = useState([]);
    // const [memberListFilter, setMemberListFilter] = useState([]);
    // const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== ""));
    const history = useHistory();
    const [data, setData] = useState({
        name: "",
        customer_id: "",
        customer_name: "",
        date_start: "",
        date_end: "",
    });
    const [error, setError] = useState({
        name: "",
        customer: "",
        date_start: "",
        date_end: "",
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                name: "",
                customer_id: "",
                customer_name: "",
                date_start: "",
                date_end: "",
            })
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/live_band');
                return;
            }
            getDetails(slug);
        } else {
            history.push('/live_band');
        }
    }, [])

    const getDetails = async (slug) => {
        let params = { id: slug };
        let res = await getLiveBand(slug);

        setData({
            name: "",
            customer_id: "",
            customer_name: "",
            date_start: "",
            date_end: "",
        });

        if (res.status === 'success') {
            let result = res.data;

            setData({
                name: (result.name) ? result.name : "",
                customer_id: (result.customer_id) ? result.customer_id : "",
                customer_name: (result.customer_name) ? result.customer_name : "",
                date_start: (result.live_band_start) ? moment(result.live_band_start).format("YYYY-MM-DD HH:mm") : "",
                date_end: (result.live_band_end) ? moment(result.live_band_end).format("YYYY-MM-DD HH:mm") : "",
            });

            if (result.customer_name) {
                setAutoCompleteValue({ filter_name: result.customer_name });
                setShowRemoveAutoCompleteValue(true);
                setAutoCompleteData([]);
            }

        } else {
            history.push('/live_band');
        }
    }

    const submitForm = async () => {
        let check = true;
        let error = {
            name: "",
            customer: "",
            date_start: "",
            date_end: "",
        }

        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired");
        }

        if (!data.customer_id) {
            check = false;
            error.customer = translate(lang, "errorRequired");
        }

        if (!data.date_start) {
            check = false;
            error.date_start = translate(lang, "errorRequired");
        }

        if (!data.date_end) {
            check = false;
            error.date_end = translate(lang, "errorRequired");
        }

        setError({ ...error });
        if (check) {
            const formData = {
                "name": data.name,
                "customer_id": data.customer_id,
                "merchant_id": merchant_id,
                "date_start": data.date_start,
                "date_end": data.date_end,
            }

            if (type == 'create') {
                await addLiveBand(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/live_band');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type == 'edit') {
                await editLiveBand(formData, slug).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/live_band');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    let inputEventStartProps = {
        placeholder: translate(lang, 'dateStart'),
        name: "date_start",
    };

    let inputEventEndProps = {
        placeholder: translate(lang, 'dateEnd'),
        name: "date_end",
    };

    const onDateChange = (e, _props) => {
        if (type === 'view') return;
        if (e._d) {
            const convertedDate = moment(e._d).format(translate(lang, "date_time_format"));
            let temp = data;
            temp[_props.name] = convertedDate;
            setData({ ...temp });
        }
    }

    // AUTOCOMPLETE START
    const [autoCompleteDatas, setAutoCompleteData] = useState([]);
    const [showRemoveAutoCompleteValue, setShowRemoveAutoCompleteValue] = useState(false);
    const [autocompleteValue, setAutoCompleteValue] = useState({
        filter_name: "",
        filter_product_input: ""
    });

    const timerRef = useRef(null);

    async function autoComplete() {
        let query = { input: autocompleteValue.filter_name, limit: 5 }

        let res = await getCustomerOnlyAutoComplete(query);

        if (res?.status === 'success') {
            setAutoCompleteData(res.data)
        } else {
        }
    }

    const autocompleteOnChange = (e, type) => {
        const tmp = autocompleteValue;
        tmp[e.target.name] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        if (!e.target.value) {
            setAutoCompleteData([]);
        }

        if (type == 'customer') {
            if (autocompleteValue.filter_name) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    autoComplete();
                }, 500)
            }

            let tmp_data = data;
            tmp_data.customer_name = autocompleteValue.filter_name;
            setData({ ...tmp_data });
        }
    }

    const autocompleteOnSelect = (e, value) => {
        let tmp_data = data;

        tmp_data['customer_id'] = (value.id) ? value.id : "";
        tmp_data['customer_name'] = (value.name) ? value.name : "";

        setData({ ...tmp_data });

        setAutoCompleteValue({ filter_name: value.name });
        setShowRemoveAutoCompleteValue(true);
        setAutoCompleteData([]);
    }

    const menuStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        position: 'absolute',
        left: '0px',
        top: '40px',
        zIndex: "9"
    };

    const autoCompleteClose = (e) => {
        e.preventDefault();

        const tmp = autocompleteValue;
        tmp['filter_name'] = e.target.value;
        setAutoCompleteValue({ ...tmp });

        let tmp_data = data;

        tmp_data['customer_id'] = "";
        tmp_data['customer_name'] = "";

        setData({ ...tmp_data });

        setAutoCompleteData([]);
        setShowRemoveAutoCompleteValue(false);
    }

    // AUTOCOMPLETE END

    const [currentPage, setCurrentPage] = useState(1);
    const [loadingData, setLoadingData] = useState(false);
    const [songData, setSongData] = useState({});
    const [filter, setFilter] = useState({
        filter_sort_by: "",
        filter_order_by: "",
    });

    useEffect(() => {
        if (type == 'edit') {
            getList()
        }
    }, [currentPage])

    async function getList() {
        setLoadingData(true);
        let query = {
            live_band_id: slug,
            sort_by: filter.filter_sort_by,
            order_by: filter.filter_order_by,
            page: currentPage,
        };

        let res = await getSongList(query);

        if (res?.status === 'success') {
            setSongData(res.data);
        } else {
        }
        setLoadingData(false);
    }

    const sortOnchange = (event) => {
        const { name } = event.target;

        const tmp = filter;
        if (tmp.filter_order_by && tmp.filter_sort_by == name && tmp.filter_order_by == 'asc') {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = "desc";
        } else {
            tmp.filter_sort_by = name;
            tmp.filter_order_by = 'asc'
        }

        setFilter({ ...tmp });
        setCurrentPage(1);
        getList();
    };

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    const pageCount = Math.ceil(songData.total_results / 20);

    return (
        <>

            <Tab.Container defaultActiveKey="Data">
                <Nav as="ul" className="nav nav-tabs" role="tablist">
                    <Nav.Item as="li" className="nav-item">
                        <Nav.Link className="nav-link" eventKey="Data" role="tab">
                            {translate(lang, "data")}
                        </Nav.Link>
                    </Nav.Item>
                    {(type != 'create')
                        ?
                        <Nav.Item className="nav-item">
                            <Nav.Link className="nav-link" eventKey="songHistory" role="tab" >
                                {translate(lang, "songHistory")}
                            </Nav.Link>
                        </Nav.Item>
                        :
                        <>
                        </>
                    }

                </Nav>
                <Tab.Content >
                    <Tab.Pane eventKey="Data">
                        <div className="d-flex flex-column flex-md-row ">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="m-0">
                                            {translate(lang, (type === "edit" ? "editLiveBand" : "addLiveBand"))}
                                        </h3>
                                    </div>
                                    <div className="card-body col-12 d-flex flex-column">
                                        <div className="form-group mb-3 required">
                                            <label htmlFor="input-name" className="form-label">{translate(lang, 'name')}</label>
                                            <input id="input-name" type="text" className="form-control" name="name" value={data.name} onChange={(e) => onInputChange(e)} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            {(error.name)
                                                ?
                                                <label className="text-danger">{error.name}</label>
                                                : <></>
                                            }
                                        </div>
                                        <div className="form-group row mb-4 required">
                                            <label htmlFor="input-customer" className="col-form-label form-label">{translate(lang, 'customer')}</label>
                                            <div className="position-relative">
                                                <Autocomplete
                                                    items={autoCompleteDatas}
                                                    getItemValue={(item) =>
                                                        item.name
                                                    }
                                                    renderItem={(item, isHighlighted) =>
                                                        <div style={{ background: isHighlighted ? 'lightgray' : 'white', padding: "5px 7px" }} key={item.id}>
                                                            {item.name} {item.phonenumber ? '- ' + item.phonecode + item.phonenumber : <></>}
                                                        </div>
                                                    }
                                                    value={autocompleteValue.filter_name} // Initially, no value is set
                                                    onChange={(e) => autocompleteOnChange(e, "customer")}
                                                    onSelect={(value, item) => autocompleteOnSelect(value, item)}
                                                    renderMenu={(items, value, style) => {
                                                        return (
                                                            (autoCompleteDatas.length > 0)
                                                                ?
                                                                <div style={{ ...style, ...menuStyle }}>
                                                                    {items}
                                                                </div>
                                                                :
                                                                <></>
                                                        );
                                                    }}
                                                    inputProps={{
                                                        className: 'form-control',
                                                        placeholder: translate(lang, 'customerName'),
                                                        name: 'filter_name',
                                                    }}
                                                    wrapperStyle={{ position: 'relative' }}
                                                />
                                                {
                                                    (showRemoveAutoCompleteValue)
                                                        ?
                                                        <a className="auto-complete-close" style={{ "top": "10px", "right": "25px" }} onClick={e => { autoCompleteClose(e) }}><i className="fas fa-times"></i></a>
                                                        : <></>
                                                }
                                                {(error.customer)
                                                    ?
                                                    <label className="text-danger">{error.customer}</label>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-3 required">
                                                    <label className="form-label">{translate(lang, 'dateStart')}</label>
                                                    <Datetime dateFormat="YYYY-MM-DD" timeFormat="HH:mm" value={data.date_start} inputProps={inputEventStartProps} onChange={(e) => onDateChange(e, inputEventStartProps)} />
                                                    {(error.date_start)
                                                        ?
                                                        <label className="text-danger">{error.date_start}</label>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-3 required">
                                                    <label className="form-label">{translate(lang, 'dateEnd')}</label>
                                                    <Datetime dateFormat="YYYY-MM-DD" timeFormat="HH:mm" value={data.date_end} inputProps={inputEventEndProps} onChange={(e) => onDateChange(e, inputEventEndProps)} />
                                                    {(error.date_end)
                                                        ?
                                                        <label className="text-danger">{error.date_end}</label>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="d-flex flex-row">
                                            <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type}`)}</button>
                                            <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/live_band')}>{translate(lang, "back")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </Tab.Pane>
                    <Tab.Pane eventKey="songHistory">
                        <div className="d-flex flex-column flex-md-row ">
                            <div className="col-12 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>{translate(lang, "songHistory")}</h3>
                                        <div className="header-button my-4">
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {(loadingData) ?
                                            <>
                                                <div className="loader-div"><i className="fas fa-spinner fa-spin"></i></div>
                                            </>
                                            :
                                            <>
                                                <div className="table-responsive">
                                                    <Table hover>
                                                        <thead>
                                                            <tr>
                                                                <td className="position-relative text-end">
                                                                    <a href="#" className={`${(filter.filter_sort_by == 'created_date') ? filter.filter_order_by : ""} filter_sort`} name="created_date" onClick={(e) => sortOnchange(e)}>{translate(lang, 'createdDate')}</a>
                                                                </td>
                                                                <td className="position-relative text-end">
                                                                    <a href="#" className={`${(filter.filter_sort_by == 'name') ? filter.filter_order_by : ""} filter_sort`} name="name" onClick={(e) => sortOnchange(e)}>{translate(lang, 'name')}</a>
                                                                </td>

                                                                <td className="position-relative">
                                                                    <a href="#" className={`${(filter.filter_sort_by == 'status') ? filter.filter_order_by : ""} filter_sort`} name="status" onClick={(e) => sortOnchange(e)}>{translate(lang, 'status')}</a>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(songData && songData.results && songData.results.length > 0)
                                                                ?
                                                                songData.results.map((value) => (
                                                                    <tr key={value.id}>
                                                                        <td scope="row" className="text-end">{value.created_date}</td>
                                                                        <td scope="row" className="text-end">{value.song_name}</td>
                                                                        <td scope="row">{value.status}</td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan={3} className="text-center">{translate(lang, 'noResult')}</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {(songData && songData.results && songData.results.length > 0)
                                                    ?
                                                    <ReactPaginate
                                                        containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                                        previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                                        nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                                        disabledClassName={"pagination__link--disabled text-muted"}
                                                        activeClassName={"pagination__link--active text-white"}
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                    />
                                                    : <></>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CreateLiveBand);